import React, { useReducer, useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const initialState = {
  isNFL: localStorage.getItem('isNFL') === 'true',
  isOpen: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'TOGGLE_MENU':
      return { ...state, isOpen: !state.isOpen };
    case 'CLOSE_MENU':
      return { ...state, isOpen: false };
    case 'TOGGLE_SPORT':
      return { ...state, isNFL: action.payload };
    case 'SET_ISNFL':
      return { ...state, isNFL: action.payload };
    default:
      return state;
  }
};

const Navbar = ({ theme, toggleTheme }) => {
  const { isAuthenticated, logout, user } = useContext(AuthContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const nflState = localStorage.getItem('isNFL') === 'true';
    dispatch({ type: 'SET_ISNFL', payload: nflState });
  }, [location]);

  const toggleMenu = () => {
    dispatch({ type: 'TOGGLE_MENU' });
  };

  const closeMenu = () => {
    dispatch({ type: 'CLOSE_MENU' });
  };

  const toggleSport = (checked) => {
    dispatch({ type: 'TOGGLE_SPORT', payload: checked });
    localStorage.setItem('isNFL', checked);
    const currentPath = location.pathname;
    const newPath = checked
      ? currentPath.replace('/cl/', '/nfl/')
      : currentPath.replace('/nfl/', '/cl/');

    navigate(newPath);
  };

  const getRoute = (baseRoute) => {
    return state.isNFL ? `/nfl${baseRoute}` : `/cl${baseRoute}`;
  };

  const handleLogoClick = () => {
    navigate(getRoute('/Matches'));
    closeMenu();
  };

  const handleSignIn = () => {
    setLoading(true);
    window.location.href = 'https://ttg.nos.plus/api/auth/twitter';
  };

  // Button style based on theme
  const getButtonStyle = () => {
    switch (theme) {
      case 'light':
        return `p-2 rounded-full transition-all duration-200 shadow-lg bg-white text-black border border-gray-300`;
      case 'sunset':
        return `p-2 rounded-full transition-all duration-200 shadow-lg bg-[#64ffda] text-black border border-[#64ffda]`;
      case 'dark':
        return `p-2 rounded-full transition-all duration-200 shadow-lg bg-gray-700 text-gray-300 border border-gray-500`;
      default:
        return `p-2 rounded-full transition-all duration-200 shadow-lg`;
    }
  };

  const themeIcons = {
    light: '☀️',
    sunset: '🌅',
    dark: '🌙',
  };

  const themeStyles = {
    light: 'bg-white text-[#064e3b]',
    sunset: 'bg-gray-800 text-[#64ffda]',
    dark: 'bg-black text-gray-300', // Adjusted for dark mode
  };

  if (loading) {
    const loadingStyles = {
      light: 'bg-gray-100 bg-opacity-75',
      dark: 'bg-black bg-opacity-75',
      sunset: 'bg-gray-900 bg-opacity-75',
    };

    return (
      <div className={`fixed inset-0 z-50 flex items-center justify-center ${loadingStyles[theme] || loadingStyles.light}`}>
        <div className="w-16 h-16 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <nav className={`p-4 ${themeStyles[theme]} shadow-md`}>
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo Image and Text */}
        <button onClick={handleLogoClick} className="text-2xl font-bold flex items-center space-x-2">
          <img src="/android-chrome-192x192.png" alt="NOS Logo" className="w-8 h-8" />
          <span>NOS Predictions</span>
        </button>
        <div className="hidden md:flex items-center space-x-4">
          <Link
            className={`px-3 py-2 rounded-md transition ${
              location.pathname.includes('/Matches')
                ? theme === 'dark'
                  ? 'bg-gray-700 text-gray-300'
                  : theme === 'sunset'
                  ? 'bg-[#64ffda] text-black'
                  : 'bg-[#064e3b] text-white'
                : 'hover:bg-opacity-10'
            }`}
            to={getRoute('/Matches')}
            onClick={closeMenu}
          >
            Matches
          </Link>
          <Link
            className={`px-3 py-2 rounded-md transition ${
              location.pathname.includes('/Results')
                ? theme === 'dark'
                  ? 'bg-gray-700 text-gray-300'
                  : theme === 'sunset'
                  ? 'bg-[#64ffda] text-black'
                  : 'bg-[#064e3b] text-white'
                : 'hover:bg-opacity-10'
            }`}
            to={getRoute('/Results')}
            onClick={closeMenu}
          >
            Results
          </Link>
          <Link
            className={`px-3 py-2 rounded-md transition ${
              location.pathname.includes('/Leaderboard')
                ? theme === 'dark'
                  ? 'bg-gray-700 text-gray-300'
                  : theme === 'sunset'
                  ? 'bg-[#64ffda] text-black'
                  : 'bg-[#064e3b] text-white'
                : 'hover:bg-opacity-10'
            }`}
            to={getRoute('/Leaderboard')}
            onClick={closeMenu}
          >
            Leaderboard
          </Link>
          <Link
            className={`px-3 py-2 rounded-md transition ${
              location.pathname.includes('/live-activities')
                ? theme === 'dark'
                  ? 'bg-gray-700 text-gray-300'
                  : theme === 'sunset'
                  ? 'bg-[#64ffda] text-black'
                  : 'bg-[#064e3b] text-white'
                : 'hover:bg-opacity-10'
            }`}
            to="/live-activities"
            onClick={closeMenu}
          >
            Live Activities
          </Link>
          <Link
            className={`px-3 py-2 rounded-md transition ${
              location.pathname.includes('/faq')
                ? theme === 'dark'
                  ? 'bg-gray-700 text-gray-300'
                  : theme === 'sunset'
                  ? 'bg-[#64ffda] text-black'
                  : 'bg-[#064e3b] text-white'
                : 'hover:bg-opacity-10'
            }`}
            to="/faq"
            onClick={closeMenu}
          >
            FAQ
          </Link>
          {isAuthenticated ? (
            <>
              <Link
                className={`px-3 py-2 rounded-md transition ${
                  location.pathname.includes(`/profile/${user?.id}`)
                    ? theme === 'dark'
                      ? 'bg-gray-700 text-gray-300'
                      : theme === 'sunset'
                      ? 'bg-[#64ffda] text-black'
                      : 'bg-[#064e3b] text-white'
                    : 'hover:bg-opacity-10'
                }`}
                to={`/profile/${user?.id}`}
                onClick={closeMenu}
              >
                {user?.name || "User"}
              </Link>
              <Link
                className={`px-3 py-2 rounded-md transition ${
                  location.pathname.includes('/settings')
                    ? theme === 'dark'
                      ? 'bg-gray-700 text-gray-300'
                      : theme === 'sunset'
                      ? 'bg-[#64ffda] text-black'
                      : 'bg-[#064e3b] text-white'
                    : 'hover:bg-opacity-10'
                }`}
                to="/settings"
                onClick={closeMenu}
              >
                Settings
              </Link>
              <button
                className="px-3 py-2 rounded-md transition hover:bg-opacity-10"
                onClick={() => {
                  logout();
                  closeMenu();
                }}
              >
                Logout
              </button>
            </>
          ) : (
            <button
              onClick={handleSignIn}
              className={`py-2 px-4 rounded-md ${getButtonStyle()} transition-all duration-200`}
            >
              Sign In with Twitter
            </button>
          )}
        </div>

        {/* Toggle Buttons */}
        <div className="flex items-center space-x-4">
          <button 
            onClick={() => toggleSport(!state.isNFL)}
            className={getButtonStyle()}
          >
            {state.isNFL ? '🏈' : '⚽'}
          </button>
          <button 
            onClick={() => toggleTheme(theme === 'light' ? 'sunset' : theme === 'sunset' ? 'dark' : 'light')}
            className={getButtonStyle()}
          >
            {themeIcons[theme]}
          </button>
          <button className="md:hidden focus:outline-none ml-4" onClick={toggleMenu}>
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              {state.isOpen ? (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              ) : (
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
              )}
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div className={`md:hidden transition-all duration-500 ease-in-out ${state.isOpen ? 'max-h-screen' : 'max-h-0 overflow-hidden'}`}>
        <div className={`px-4 pt-4 pb-4 space-y-3 ${theme === 'dark' ? 'bg-black' : theme === 'sunset' ? 'bg-gray-800' : 'bg-white'} rounded-md shadow-lg`}>
          <Link
            className={`block px-3 py-2 rounded-md transition ${
              location.pathname.includes('/Matches')
                ? theme === 'dark'
                  ? 'bg-gray-700 text-gray-300'
                  : theme === 'sunset'
                  ? 'bg-[#64ffda] text-black'
                  : 'bg-[#064e3b] text-white'
                : 'hover:bg-opacity-10'
            }`}
            to={getRoute('/Matches')}
            onClick={closeMenu}
          >
            Matches
          </Link>
          <Link
            className={`block px-3 py-2 rounded-md transition ${
              location.pathname.includes('/Results')
                ? theme === 'dark'
                  ? 'bg-gray-700 text-gray-300'
                  : theme === 'sunset'
                  ? 'bg-[#64ffda] text-black'
                  : 'bg-[#064e3b] text-white'
                : 'hover:bg-opacity-10'
            }`}
            to={getRoute('/Results')}
            onClick={closeMenu}
          >
            Results
          </Link>
          <Link
            className={`block px-3 py-2 rounded-md transition ${
              location.pathname.includes('/Leaderboard')
                ? theme === 'dark'
                  ? 'bg-gray-700 text-gray-300'
                  : theme === 'sunset'
                  ? 'bg-[#64ffda] text-black'
                  : 'bg-[#064e3b] text-white'
                : 'hover:bg-opacity-10'
            }`}
            to={getRoute('/Leaderboard')}
            onClick={closeMenu}
          >
            Leaderboard
          </Link>
          <Link
            className={`block px-3 py-2 rounded-md transition ${
              location.pathname.includes('/live-activities')
                ? theme === 'dark'
                  ? 'bg-gray-700 text-gray-300'
                  : theme === 'sunset'
                  ? 'bg-[#64ffda] text-black'
                  : 'bg-[#064e3b] text-white'
                : 'hover:bg-opacity-10'
            }`}
            to="/live-activities"
            onClick={closeMenu}
          >
            Live Activities
          </Link>
          <Link
            className={`block px-3 py-2 rounded-md transition ${
              location.pathname.includes('/faq')
                ? theme === 'dark'
                  ? 'bg-gray-700 text-gray-300'
                  : theme === 'sunset'
                  ? 'bg-[#64ffda] text-black'
                  : 'bg-[#064e3b] text-white'
                : 'hover:bg-opacity-10'
            }`}
            to="/faq"
            onClick={closeMenu}
          >
            FAQ
          </Link>
          {isAuthenticated ? (
            <>
              <Link
                className={`block px-3 py-2 rounded-md transition ${
                  location.pathname.includes(`/profile/${user?.id}`)
                    ? theme === 'dark'
                      ? 'bg-gray-700 text-gray-300'
                      : theme === 'sunset'
                      ? 'bg-[#64ffda] text-black'
                      : 'bg-[#064e3b] text-white'
                    : 'hover:bg-opacity-10'
                }`}
                to={`/profile/${user?.id}`}
                onClick={closeMenu}
              >
                {user?.name || "User"}
              </Link>
              <Link
                className={`block px-3 py-2 rounded-md transition ${
                  location.pathname.includes('/settings')
                    ? theme === 'dark'
                      ? 'bg-gray-700 text-gray-300'
                      : theme === 'sunset'
                      ? 'bg-[#64ffda] text-black'
                      : 'bg-[#064e3b] text-white'
                    : 'hover:bg-opacity-10'
                }`}
                to="/settings"
                onClick={closeMenu}
              >
                Settings
              </Link>
              <button
                className="block px-3 py-2 rounded-md transition hover:bg-opacity-10"
                onClick={() => {
                  logout();
                  closeMenu();
                }}
              >
                Logout
              </button>
            </>
          ) : (
            <button
              onClick={handleSignIn}
              className={`py-2 px-4 rounded-md ${getButtonStyle()} transition-all duration-200`}
            >
              Sign In with Twitter
            </button>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
