import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';

const Badges = ({ userId, theme }) => {
  const [predictionHistory, setPredictionHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPredictionHistory = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/api/predictions/${userId}`);
        setPredictionHistory(response.data);
      } catch (error) {
        // console.error('Error fetching prediction history:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPredictionHistory();
  }, [userId]);

  const themeStyles = {
    light: {
      text: 'text-gray-900',
      bg: 'bg-gray-100',
      border: 'border-gray-300',
      badgeBg: 'bg-white',
      badgeText: 'text-gray-800',
      badgeBorder: 'border-gray-200',
      highlight: 'text-[#064e3b]',
    },
    dark: {
      text: 'text-white',
      bg: 'bg-gray-800',
      border: 'border-gray-600',
      badgeBg: 'bg-gray-700',
      badgeText: 'text-gray-200',
      badgeBorder: 'border-gray-500',
      highlight: 'text-white',
    },
    sunset: {
      text: 'text-gray-200',
      bg: 'bg-gray-700',
      border: 'border-gray-500',
      badgeBg: 'bg-gray-600',
      badgeText: 'text-gray-100',
      badgeBorder: 'border-gray-400',
      highlight: 'text-[#64ffda]',
    },
  };

  const styles = themeStyles[theme] || themeStyles.light;

  // Example badges logic
  const totalPredictions = predictionHistory.length;
  const correctPredictions = predictionHistory.filter((p) => p.status === 'Correct').length;
  const incorrectPredictions = predictionHistory.filter((p) => p.status === 'Incorrect').length;
  const longestStreak = Math.max(...predictionHistory.map(p => p.streak || 0));

  const badges = [
    { id: 1, name: '🏅 First Prediction', description: 'Made your first prediction.', condition: totalPredictions > 0 },
    { id: 2, name: '🔟 10 Predictions', description: 'Made 10 predictions.', condition: totalPredictions >= 10 },
    { id: 3, name: '✅ First Correct Prediction', description: 'Made your first correct prediction.', condition: correctPredictions > 0 },
    { id: 4, name: '🔥 5 Correct Predictions', description: 'Achieved 5 correct predictions.', condition: correctPredictions >= 5 },
    { id: 5, name: '🏆 50 Predictions', description: 'Made 50 predictions.', condition: totalPredictions >= 50 },
    { id: 6, name: '🎯 20 Correct Predictions', description: 'Achieved 20 correct predictions.', condition: correctPredictions >= 20 },
    { id: 7, name: '💯 Accuracy Expert', description: '75% accuracy with 20+ predictions.', condition: correctPredictions / totalPredictions >= 0.75 && totalPredictions >= 20 },
    { id: 8, name: '🚀 3-Day Streak', description: 'Predicted for 3 consecutive days.', condition: longestStreak >= 3 },
    { id: 9, name: '⚡ Quick Thinker', description: 'Made a prediction within the last hour before the match.', condition: predictionHistory.some(p => p.quickPrediction) },
    { id: 10, name: '🌟 Perfect Streak', description: '5 correct predictions in a row.', condition: predictionHistory.some(p => p.perfectStreak >= 5) },
    { id: 11, name: '🌟 Super Streak', description: '10 correct predictions in a row.', condition: predictionHistory.some(p => p.perfectStreak >= 10) },
    { id: 12, name: '🕒 Last Minute', description: 'Made a correct prediction just before the match ended.', condition: predictionHistory.some(p => p.lastMinuteCorrect) },
    { id: 13, name: '💎 Diamond Predictor', description: 'Achieved 50 correct predictions.', condition: correctPredictions >= 50 },
    { id: 14, name: '👑 King of Predictions', description: 'Achieved 100 correct predictions.', condition: correctPredictions >= 100 },
    { id: 15, name: '🎉 Welcome Back', description: 'Returned to make a prediction after a long break.', condition: predictionHistory.some(p => p.returnedAfterBreak) },
  ].filter((badge) => badge.condition);

  if (loading) {
    return (
      <div className={`p-3 sm:p-4 rounded-lg shadow-lg ${styles.bg} ${styles.border}`}>
        <div className="flex justify-center items-center">
          <div className="w-8 h-8 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
        </div>
      </div>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className={`p-6 rounded-lg shadow-lg border ${styles.bg} ${styles.border}`}
    >
      <h3 className={`text-2xl font-bold mb-6 ${styles.text} ${styles.highlight}`}>
        🏅 Your Badges
      </h3>
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-4">
        {badges.length > 0 ? (
          badges.map((badge) => (
            <motion.div
              key={badge.id}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
              className={`flex flex-col items-center p-4 ${styles.badgeBg} ${styles.badgeBorder} rounded-lg shadow-md`}
            >
              <div className={`w-16 h-16 mb-2 rounded-full flex items-center justify-center ${styles.badgeText} text-3xl`}>
                {badge.name.split(' ')[0]}
              </div>
              <span className={`text-sm font-semibold ${styles.badgeText} text-center`}>
                {badge.name.split(' ').slice(1).join(' ')}
              </span>
              <span className={`text-xs ${styles.badgeText} text-center mt-2`}>
                {badge.description}
              </span>
            </motion.div>
          ))
        ) : (
          <p className={`${styles.text} col-span-4 text-center`}>No badges earned yet.</p>
        )}
      </div>
    </motion.div>
  );
};

export default Badges;
