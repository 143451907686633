// src/pages/AuthCallback.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token'); // Extract the token from the URL

    if (token) {
      localStorage.setItem('authToken', token); // Store the token in local storage
      navigate('/settings'); // Redirect to the settings or any authenticated page
    } else {
      navigate('/login'); // Redirect to login if no token is found
    }
  }, [navigate]);

  return null; // Optionally, show a loading spinner or message
};

export default AuthCallback;
