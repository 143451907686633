import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Footer = ({ theme }) => {
  const location = useLocation();

  const themeStyles = {
    light: {
      bg: 'bg-white text-[#064e3b]',
      text: 'text-black',
      border: 'border-gray-300',
      activeBg: 'bg-[#064e3b]',
      activeText: 'text-white',
    },
    sunset: {
      bg: 'bg-gray-800 text-[#64ffda]',
      text: 'text-gray-200', // Adjusted text color for sunset mode
      border: 'border-[#64ffda]',
      activeBg: 'bg-[#64ffda]',
      activeText: 'text-black',
    },
    dark: {
      bg: 'bg-black text-gray-300',
      text: 'text-gray-300',
      border: 'border-gray-500',
      activeBg: 'bg-gray-700',
      activeText: 'text-gray-300',
    },
  };

  const styles = themeStyles[theme] || themeStyles.light;

  const getButtonStyle = (isActive) => {
    const baseStyle = 'p-2 text-xs md:text-sm rounded-full transition-all duration-200 shadow-lg'; // Adjusted padding
    const activeStyle = isActive
      ? `${styles.activeBg} ${styles.activeText}`
      : `${styles.bg} ${styles.text} ${styles.border}`;

    return `${baseStyle} ${activeStyle}`;
  };

  return (
    <footer className={`p-2 mt-6 ${styles.bg} shadow-inner`}>
      <div className={`container mx-auto text-center text-xs md:text-sm ${styles.text}`}>
        <p className={styles.text}>Made with 💚 by Luzzo for the Nosana Community.</p>
        <div className="mt-2 space-x-2 flex justify-center flex-wrap gap-2">
          <button className={getButtonStyle(location.pathname === '/privacy-policy')}>
            <Link to="/privacy-policy" className="no-underline text-inherit" aria-label="Privacy Policy">
              Privacy Policy
            </Link>
          </button>
          <button className={getButtonStyle(location.pathname === '/terms-of-service')}>
            <Link to="/terms-of-service" className="no-underline text-inherit" aria-label="Terms of Service">
              Terms of Service
            </Link>
          </button>
          <button className={getButtonStyle(false)}>
            <a href="mailto:luzzo@nos.plus" className="no-underline text-inherit" aria-label="Contact Luzzo">
              Contact
            </a>
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
