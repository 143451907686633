import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { formatDistanceToNowStrict } from 'date-fns';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { motion } from 'framer-motion';

const LoadingSpinner = ({ theme }) => (
  <div
    className={`fixed inset-0 flex items-center justify-center ${
      theme === 'dark'
        ? 'bg-black bg-opacity-75'
        : theme === 'sunset'
        ? 'bg-gray-900 bg-opacity-75'
        : 'bg-gray-100 bg-opacity-75'
    }`}
  >
    <div className="w-16 h-16 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
  </div>
);

const RecentActivity = ({ userId, theme, isActive }) => {
  const [detailedActivities, setDetailedActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [matchCache, setMatchCache] = useState({});
  const previousActivitiesRef = useRef(null);
  const refs = useRef([]);

  const themeStyles = {
    light: {
      bg: 'bg-white',
      text: 'text-gray-900',
      cardBg: 'bg-white',
      border: 'border-gray-200',
      hover: 'hover:bg-gray-100 hover:bg-opacity-50',
    },
    dark: {
      bg: 'bg-[#121212]',
      text: 'text-white',
      cardBg: 'bg-[#121212]',
      border: 'border-gray-600',
      hover: 'hover:bg-[#1c1c1c] hover:bg-opacity-70',
    },
    sunset: {
      bg: 'bg-gray-800',
      text: 'text-gray-200',
      cardBg: 'bg-gray-800',
      border: 'border-[#64ffda]',
      hover: 'hover:bg-gray-800 hover:bg-opacity-70',
    },
  };

  const fetchMatchDetails = async (matchId, league) => {
    if (matchCache[matchId]) {
      return matchCache[matchId];
    }

    const url =
      league === 'cl'
        ? `https://ttg.nos.plus/api/cl/matches/basic/${matchId}`
        : `https://ttg.nos.plus/api/nfl/matches/basic/${matchId}`;

    try {
      const response = await axios.get(url);
      const matchDetails = {
        homeTeam: response.data.strHomeTeam,
        awayTeam: response.data.strAwayTeam,
        date: response.data.dateEvent,
        time: response.data.strTime,
        venue: response.data.strVenue,
        homeTeamBadge: response.data.strHomeTeamBadge,
        awayTeamBadge: response.data.strAwayTeamBadge,
        league: response.data.league,
        matchStatus: response.data.strStatus,
        venueLocation: response.data.strVenueLocation,
        venueCountry: response.data.strVenueCountry,
      };

      setMatchCache((prevCache) => ({
        ...prevCache,
        [matchId]: matchDetails,
      }));

      return matchDetails;
    } catch (error) {
      // console.error('Error fetching match details:', error);
      return null;
    }
  };

  const fetchActivities = async () => {
    try {
      const response = await axios.get(`https://ttg.nos.plus/api/user/${userId}/recent-activities`);
      const activities = response.data;

      if (JSON.stringify(activities) === JSON.stringify(previousActivitiesRef.current)) {
        return;
      }

      previousActivitiesRef.current = activities;

      const detailed = await Promise.all(
        activities.map(async (activity, index) => {
          const matchDetails = await fetchMatchDetails(activity.match_id, activity.league);

          let changedPrediction = false;
          let previousPrediction = null;

          if (index < activities.length - 1 && activities[index + 1].match_id === activity.match_id) {
            previousPrediction = activities[index + 1].prediction;
            if (previousPrediction !== activity.prediction) {
              changedPrediction = true;
            }
          }

          return {
            ...activity,
            matchDetails,
            changedPrediction,
            previousPrediction,
          };
        })
      );

      setDetailedActivities(detailed);
    } catch (error) {
      // console.error('Error fetching recent activities:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isActive) {
      fetchActivities();
      const intervalId = setInterval(fetchActivities, 5000);
      return () => clearInterval(intervalId);
    }
  }, [isActive, userId]);

  if (loading) {
    return <LoadingSpinner theme={theme} />;
  }

  return (
    <div className={`p-2 sm:p-4 mb-4 rounded-lg shadow-lg ${themeStyles[theme].bg} ${themeStyles[theme].border}`}>
      <h3 className={`text-lg sm:text-xl font-bold mb-4 ${themeStyles[theme].text}`}>Recent Activity</h3>
      <TransitionGroup>
        {detailedActivities.map((activity, index) => {
          const nodeRef = refs.current[index] || React.createRef();
          refs.current[index] = nodeRef;

          return (
            <CSSTransition key={activity.id} timeout={500} classNames="slide" nodeRef={nodeRef}>
              <motion.div
                ref={nodeRef}
                whileHover={{ scale: 1.02 }}
                className={`shadow-md rounded-lg p-4 mb-4 flex items-start ${themeStyles[theme].cardBg} ${themeStyles[theme].border} ${themeStyles[theme].hover} transition-all duration-200 ease-in-out cursor-pointer`}
              >
                <div className="flex-1">
                  <div className="flex items-center">
                    <img
                      src={activity.matchDetails?.homeTeamBadge}
                      alt={activity.matchDetails?.homeTeam}
                      className="w-10 h-10 mr-2"
                    />
                    <span className={`${themeStyles[theme].text} font-semibold`}>
                      {activity.matchDetails?.homeTeam}
                    </span>
                    <span className={`${themeStyles[theme].text} mx-2`}>vs</span>
                    <span className={`${themeStyles[theme].text} font-semibold`}>
                      {activity.matchDetails?.awayTeam}
                    </span>
                    <img
                      src={activity.matchDetails?.awayTeamBadge}
                      alt={activity.matchDetails?.awayTeam}
                      className="w-10 h-10 ml-2"
                    />
                  </div>
                  <div className="mt-2">
                    <span className={`${themeStyles[theme].text} text-sm`}>
                      {activity.changedPrediction && activity.previousPrediction
                        ? `Changed from ${activity.previousPrediction} to ${activity.prediction}`
                        : `Prediction: ${activity.prediction}`}
                    </span>
                  </div>
                  <div className="mt-2">
                    <span className="text-xs text-gray-500">
                      {formatDistanceToNowStrict(new Date(activity.created_at))} ago
                    </span>
                  </div>
                </div>
              </motion.div>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    </div>
  );
};

export default RecentActivity;
