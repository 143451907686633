import React, { useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';

const LoginPage = ({ theme }) => {
  const { loginWithTwitter } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const themeStyles = {
    light: {
      bg: 'bg-gray-100',
      text: 'text-gray-900',
      button: 'bg-blue-600 text-white hover:bg-blue-700',
    },
    dark: {
      bg: 'bg-black',
      text: 'text-white',
      button: 'bg-gray-700 text-white hover:bg-gray-900',
    },
    sunset: {
      bg: 'bg-gray-900',
      text: 'text-gray-400',
      button: 'bg-[#64ffda] text-black hover:bg-[#52d6b5]',
    },
  };

  const styles = themeStyles[theme] || themeStyles.light;

  const LoadingSpinner = ({ theme }) => {
    const themeStyles = {
      light: {
        overlay: 'bg-gray-100 bg-opacity-75',
      },
      dark: {
        overlay: 'bg-black bg-opacity-75',
      },
      sunset: {
        overlay: 'bg-gray-900 bg-opacity-75',
      },
    };

    const styles = themeStyles[theme] || themeStyles.light;

    return (
      <div className={`fixed inset-0 flex items-center justify-center ${styles.overlay}`}>
        <div className="w-16 h-16 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  };

  const loginWithTwitterHandler = () => {
    setLoading(true);
    window.location.href = 'https://ttg.nos.plus/api/auth/twitter';
  };

  if (loading) {
    return <LoadingSpinner theme={theme} />;
  }

  return (
    <div className={`min-h-screen flex items-center justify-center ${styles.bg}`}>
      <div className={`${styles.bg} p-8 rounded-lg shadow-md w-full max-w-md`}>
        <h2 className={`text-3xl font-bold mb-6 text-center ${styles.text}`}>Login with Twitter</h2>
        <button
          onClick={loginWithTwitterHandler}
          className={`w-full py-3 ${styles.button} rounded-lg transition`}
        >
          Login with Twitter
        </button>
      </div>
    </div>
  );
};

export default LoginPage;
