import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';

const FAQPage = ({ theme }) => {
  const themeStyles = {
    light: {
      bg: 'bg-gray-100',
      text: 'text-gray-900',
      cardBg: 'bg-white',
      border: 'border-gray-200',
      userText: 'text-gray-600',
      highlight: 'text-[#064e3b]',
      hover: 'hover:bg-gray-100 hover:bg-opacity-50',
    },
    dark: {
      bg: 'bg-black',
      text: 'text-white',
      cardBg: 'bg-[#121212]',
      border: 'border-gray-600',
      userText: 'text-gray-400',
      highlight: 'text-white',
      hover: 'hover:bg-[#1c1c1c] hover:bg-opacity-70',
    },
    sunset: {
      bg: 'bg-gray-900',
      text: 'text-gray-400',
      cardBg: 'bg-gray-800',
      border: 'border-[#64ffda]',
      userText: 'text-[#64ffda]',
      highlight: 'text-[#52d6b5]',
      hover: 'hover:bg-gray-800 hover:bg-opacity-70',
    },
  };

  const styles = themeStyles[theme] || themeStyles.light;

  const faqData = [
    {
      title: 'Matches Page',
      questions: [
        {
          question: 'How do I predict a match?',
          answer:
            'To predict a match, click on a team’s logo or the vs symbol on the match card. Clicking on a team’s logo will predict that team to win, while clicking on the vs symbol predicts a draw.',
        },
        {
          question: 'How can I view predictions for a match?',
          answer:
            'Hover over the match card on desktop or long press on mobile to reveal predictions. You will see predictions from other users displayed with their logos. Click on a user’s logo to view their profile, or click elsewhere in the prediction box to see all images in full.',
        },
        {
          question: 'What information is available in the match details?',
          answer:
            'Clicking or tapping on a match card reveals the match details, including an overview, users\' predictions in a bar chart, upcoming and latest matches, lineups, timeline, and additional information if available.',
        },
        {
          question: 'How do I filter matches?',
          answer:
            'Use the filter bar at the top of the page to filter by match status, date (oldest or newest), or show only your predictions and matches you haven’t predicted yet.',
        },
        {
          question: 'How can I switch leagues?',
          answer:
            'Click the American football or soccer football logo in the top right corner of the navbar to switch between NFL and Champions League.',
        },
      ],
    },
    {
      title: 'Results Page',
      questions: [
        {
          question: 'Can I predict matches on the results page?',
          answer:
            'No, predictions are not possible on the results page. Here, you can only view the results and predictions.',
        },
        {
          question: 'How are correct and incorrect predictions displayed?',
          answer:
            'Correct predictions are shown with a green border, and incorrect predictions are shown with a red border.',
        },
        {
          question: 'How do I switch leagues on the results page?',
          answer:
            'Click the American football or soccer football logo in the top right corner of the navbar to switch between NFL and Champions League.',
        },
      ],
    },
    {
      title: 'Leaderboard Page',
      questions: [
        {
          question: 'What can I see on the leaderboard page?',
          answer:
            'The leaderboard page shows the current rankings of users and a chart of the historical leaderboard over different timeframes (all time, last week, last month, or custom).',
        },
        {
          question: 'How do I customize the leaderboard chart?',
          answer:
            'Use the + or x emojis to add or remove users from the chart. Click on a user’s name or profile picture to view their profile.',
        },
      ],
    },
    {
      title: 'Profile Page',
      questions: [
        {
          question: 'What information is available on my profile page?',
          answer:
            'Your profile page displays your Twitter image, username, bio, and link to your Twitter profile, as well as your league scores.',
        },
        {
          question: 'What filters can I use on my profile page?',
          answer:
            'You can filter by prediction status (correct, incorrect, open), league (NFL or Champions League), match status (live, finished, upcoming), or date.',
        },
        {
          question: 'What sections are available on the profile menu?',
          answer:
            'The menu includes: Predictions, Streaks, Badges, Recent Activity.',
        },
      ],
    },
    {
      title: 'Live Activities Page',
      questions: [
        {
          question: 'What can I see on the live activities page?',
          answer:
            'This page displays the 5 latest activities by all users in real time. Click on an activity card to see the match details.',
        },
      ],
    },
    {
      title: 'Settings Page',
      questions: [
        {
          question: 'What information can I manage on the settings page?',
          answer:
            'On the settings page, you can view and update your details, enter your Solana address (changeable once per week), and see your winnings with transaction links and timestamps.',
        },
      ],
    },
    {
      title: 'Additional Features',
      questions: [
        {
          question: 'How do I switch between leagues?',
          answer:
            'Click the football soccer or NFL emoji in the top right corner of any page to switch leagues.',
        },
        {
          question: 'What themes are available?',
          answer:
            'You can choose from three themes: Light (default), Sunset (a mix between light and dark), and Dark Mode (real dark mode). Themes can be changed on all pages.',
        },
      ],
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className={`min-h-screen ${styles.bg} p-6`}>
      <div className={`max-w-4xl mx-auto p-6 rounded-lg ${styles.cardBg} ${styles.border} shadow-md`}>
        <h1 className={`text-3xl font-bold mb-4 ${styles.highlight}`}>FAQ</h1>

        {faqData.map((section, index) => (
          <div key={index} className="mb-6">
            <h2
              className={`text-2xl font-semibold cursor-pointer mb-2 flex items-center justify-between ${styles.text}`}
              onClick={() => toggleAccordion(index)}
            >
              {section.title}
              {openIndex === index ? (
                <ChevronUpIcon className="w-5 h-5" />
              ) : (
                <ChevronDownIcon className="w-5 h-5" />
              )}
            </h2>
            {openIndex === index && (
              <div className="space-y-4">
                {section.questions.map((q, i) => (
                  <div key={i} className={`${styles.userText} transition-all duration-300 ease-in-out`}>
                    <p><strong>Q: {q.question}</strong></p>
                    <p>A: {q.answer}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQPage;
