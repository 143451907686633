import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { formatDistanceToNowStrict } from 'date-fns';
import { useNavigate, useLocation } from 'react-router-dom';
import MatchDetail from '../components/MatchDetail';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './LiveActivitiesPage.css';

const LoadingSpinner = ({ theme }) => {
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center ${
        theme === 'dark'
          ? 'bg-black bg-opacity-75'
          : theme === 'sunset'
          ? 'bg-gray-900 bg-opacity-75'
          : 'bg-gray-100 bg-opacity-75'
      }`}
    >
      <div className="w-16 h-16 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
    </div>
  );
};

function useInterval(callback, delay) {
  useEffect(() => {
    const intervalId = setInterval(callback, delay);
    return () => clearInterval(intervalId);
  }, [callback, delay]);
}

const LiveActivitiesPage = ({ theme }) => {
  const location = useLocation();
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [matchCache, setMatchCache] = useState({});
  const previousActivitiesRef = useRef(null);
  const navigate = useNavigate();
  const refs = useRef([]);

  const themeStyles = {
    light: {
      bg: 'bg-gray-100',
      text: 'text-gray-900',
      cardBg: 'bg-white',
      border: 'border-gray-200',
      userText: 'text-gray-600',
      highlight: 'text-[#064e3b]',
      hover: 'hover:bg-gray-100 hover:bg-opacity-50',
      correct: 'bg-green-100',
      incorrect: 'bg-red-100',
      correctHover: 'hover:bg-green-200',
      incorrectHover: 'hover:bg-red-200',
      highlightUser: 'bg-green-50',
    },
    dark: {
      bg: 'bg-black',
      text: 'text-white',
      cardBg: 'bg-[#121212]',
      border: 'border-gray-600',
      userText: 'text-gray-400',
      highlight: 'text-white',
      hover: 'hover:bg-[#1c1c1c] hover:bg-opacity-70',
      correct: 'bg-[#004400]',
      incorrect: 'bg-[#440000]',
      correctHover: 'hover:bg-[#006600] hover:bg-opacity-50',
      incorrectHover: 'hover:bg-[#660000] hover:bg-opacity-50',
      highlightUser: 'bg-[#002200]',
    },
    sunset: {
      bg: 'bg-gray-900',
      text: 'text-gray-400',
      cardBg: 'bg-gray-800',
      border: 'border-[#64ffda]',
      userText: 'text-[#64ffda]',
      highlight: 'text-[#52d6b5]',
      hover: 'hover:bg-gray-800 hover:bg-opacity-70',
      correct: 'bg-[#005f37]',
      incorrect: 'bg-[#9b0000]',
      correctHover: 'hover:bg-[#007a4f] hover:bg-opacity-50',
      incorrectHover: 'hover:bg-[#b20000] hover:bg-opacity-50',
      highlightUser: 'bg-[#00311f]',
    },
  };

  const fetchMatchDetails = async (matchId) => {
    if (matchCache[matchId]) {
      return matchCache[matchId];
    }

    try {
      const response = await axios.get(`https://ttg.nos.plus/api/cl/matches/basic/${matchId}`);
      const matchDetails = response.data;

      setMatchCache((prevCache) => ({
        ...prevCache,
        [matchId]: matchDetails,
      }));

      return matchDetails;
    } catch (error) {
      // console.error('Error fetching match details:', error);
      return null;
    }
  };

  const fetchActivities = async () => {
    try {
      const response = await axios.get('/api/live-activities');
      const activities = response.data;

      const previousActivities = previousActivitiesRef.current;

      if (JSON.stringify(activities) === JSON.stringify(previousActivities)) {
        return;
      }

      previousActivitiesRef.current = activities;

      const activitiesWithMatchDetails = await Promise.all(
        activities.map(async (activity, index) => {
          const matchDetails = await fetchMatchDetails(activity.match_id);
          return { ...activity, matchDetails };
        })
      );

      setActivities(activitiesWithMatchDetails.slice(0, 5));
    } catch (error) {
      // console.error('Error fetching live activities:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && location.pathname === '/live-activities') {
        fetchActivities();
        const intervalId = setInterval(fetchActivities, 5000);

        return () => clearInterval(intervalId);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    if (document.visibilityState === 'visible' && location.pathname === '/live-activities') {
      fetchActivities();
      const intervalId = setInterval(fetchActivities, 5000);
      return () => clearInterval(intervalId);
    }

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [location]);

  useInterval(() => setActivities([...activities]), 1000);

  const handleUserClick = (userId) => {
    navigate(`/profile/${userId}`);
  };

  const handleCardClick = (match) => {
    setSelectedMatch(match);
  };

  const getPredictionChangeVisual = (activity, index) => {
    if (index < activities.length - 1) {
      const previousPrediction = activities[index + 1]?.prediction;
      if (previousPrediction && previousPrediction !== activity.prediction) {
        return (
          <div className="flex items-center">
            <span className="mr-2">{previousPrediction}</span>
            <svg
              className="w-4 h-4 text-green-500"
              fill="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M2 12l18 0"></path>
              <path d="M13 5l7 7-7 7"></path>
            </svg>
            <span className="ml-2">{activity.prediction}</span>
          </div>
        );
      }
    }
    return <span>{activity.prediction}</span>;
  };

  if (loading) {
    return <LoadingSpinner theme={theme} />;
  }

  return (
    <div className={`min-h-screen ${themeStyles[theme].bg} ${themeStyles[theme].text} p-8 flex justify-center`}>
      <div className="max-w-3xl w-full space-y-4">
        <h2 className="text-3xl font-bold text-center mb-8">Live Activities</h2>
        <TransitionGroup>
          {activities.map((activity, index) => {
            const nodeRef = refs.current[index] || React.createRef();
            refs.current[index] = nodeRef;

            return (
              <CSSTransition key={activity.id} timeout={500} classNames="slide" nodeRef={nodeRef}>
                <div
                  ref={nodeRef}
                  className={`shadow-md rounded-lg p-4 mb-4 flex items-start ${themeStyles[theme].cardBg} ${themeStyles[theme].border} ${themeStyles[theme].hover} transition-all duration-200 ease-in-out cursor-pointer`}
                  onClick={() => handleCardClick(activity.matchDetails)}
                >
                  <img
                    src={activity.profile_picture}
                    alt={activity.name}
                    className="w-12 h-12 rounded-full mr-4 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUserClick(activity.user_id);
                    }}
                  />
                  <div className="flex-1">
                    <h4
                      className={`font-semibold ${themeStyles[theme].highlight} cursor-pointer`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleUserClick(activity.user_id);
                      }}
                    >
                      {activity.name}
                    </h4>
                    <span className={`text-sm ${themeStyles[theme].userText}`}>
                      {getPredictionChangeVisual(activity, index)} for{' '}
                      {activity.matchDetails
                        ? `${activity.matchDetails.strHomeTeam} vs ${activity.matchDetails.strAwayTeam}`
                        : 'a match'}
                    </span>
                    <div className="flex items-center mt-2">
                      <img
                        src={activity.matchDetails?.strHomeTeamBadge}
                        alt={activity.matchDetails?.strHomeTeam}
                        className="w-8 h-8 mr-2"
                      />
                      <img
                        src={activity.matchDetails?.strAwayTeamBadge}
                        alt={activity.matchDetails?.strAwayTeam}
                        className="w-8 h-8 mr-2"
                      />
                      <span className="text-xs text-gray-500">
                        {formatDistanceToNowStrict(new Date(activity.created_at))} ago
                      </span>
                    </div>
                  </div>
                </div>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
      </div>

      {selectedMatch && (
        <MatchDetail
          match={selectedMatch}
          onClose={() => setSelectedMatch(null)}
          theme={theme}
          league={selectedMatch.league === 'UEFA Champions League' ? 'cl' : selectedMatch.league}
          onUpdatePrediction={fetchActivities}
        />
      )}
    </div>
  );
};

export default LiveActivitiesPage;
