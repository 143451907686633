import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import MatchDetail from '../components/MatchDetail';
import Streaks from '../components/Streaks';
import Badges from '../components/Badges';
import RecentActivity from '../components/RecentActivity';
import { Tab, Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';

const LoadingSpinner = ({ theme }) => {
  return (
    <div
      className={`fixed inset-0 flex items-center justify-center ${
        theme === 'dark'
          ? 'bg-black bg-opacity-75'
          : theme === 'sunset'
          ? 'bg-gray-900 bg-opacity-75'
          : 'bg-gray-100 bg-opacity-75'
      }`}
    >
      <div className="w-16 h-16 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
    </div>
  );
};

const ProfilePage = ({ theme }) => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [nflScore, setNflScore] = useState(0);
  const [clScore, setClScore] = useState(0);
  const [predictions, setPredictions] = useState([]);
  const [filteredPredictions, setFilteredPredictions] = useState([]);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingMatchDetails, setLoadingMatchDetails] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState('All');
  const [selectedLeague, setSelectedLeague] = useState('All');
  const [selectedStatus, setSelectedStatus] = useState('All');
  const [selectedSortOrder, setSelectedSortOrder] = useState('desc');
  const [activities, setActivities] = useState([]);

  const predictionsPerPage = 10;

  const themeStyles = {
    light: {
      bg: 'bg-gray-100',
      text: 'text-gray-900',
      cardBg: 'bg-white',
      border: 'border-gray-200',
      userText: 'text-gray-600',
      highlight: 'text-[#064e3b]',
      hover: 'hover:bg-gray-100 hover:bg-opacity-50',
      correct: 'bg-green-100',
      incorrect: 'bg-red-100',
      correctHover: 'hover:bg-green-200',
      incorrectHover: 'hover:bg-red-200',
      highlightUser: 'bg-green-50',
      thickBottomBorder: 'border-b-4 border-gray-200', // Thicker bottom border for light theme
    },
    dark: {
      bg: 'bg-black',
      text: 'text-white',
      cardBg: 'bg-[#121212]',
      border: 'border-gray-600',
      userText: 'text-gray-400',
      highlight: 'text-white',
      hover: 'hover:bg-[#1c1c1c] hover:bg-opacity-70',
      correct: 'bg-[#00440028]',
      incorrect: 'bg-[#44000028]',
      correctHover: 'hover:bg-[#0044003E]',
      incorrectHover: 'hover:bg-[#4400003E]',
      highlightUser: 'bg-[#00220033]',
      thickBottomBorder: 'border-b-4 border-gray-600', // Thicker bottom border for dark theme
    },
    sunset: {
      bg: 'bg-gray-900',
      text: 'text-gray-400',
      cardBg: 'bg-gray-800',
      border: 'border-[#64ffda]',
      userText: 'text-[#64ffda]',
      highlight: 'text-[#52d6b5]',
      hover: 'hover:bg-gray-800 hover:bg-opacity-70',
      correct: 'bg-[#005f3728]',
      incorrect: 'bg-[#9b000028]',
      correctHover: 'hover:bg-[#005f373E]',
      incorrectHover: 'hover:bg-[#9b00003E]',
      highlightUser: 'bg-[#00311f33]',
      thickBottomBorder: 'border-b-4 border-[#64ffda]', // Thicker bottom border for sunset theme
    },
  };  
  
  const styles = themeStyles[theme] || themeStyles.light;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('authToken');
  
        // Fetch the user profile data
        const userResponse = await axios.get(`/api/profile/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        // console.log('User Response:', userResponse.data);
  
        setUser(userResponse.data.user);
  
        const detailedPredictions = userResponse.data.predictions.map((prediction) =>
          normalizeMatchData({
            ...prediction,
            status: prediction.status === 'Final' ? 'Finished' : prediction.status,
            matchStatus: prediction.status === 'Final' ? 'Finished' : prediction.matchStatus,
            when: calculateTimeUntil(prediction.match_time),
          })
        );
  
        const sortedPredictions = detailedPredictions.sort(
          (a, b) => new Date(b.match_time) - new Date(a.match_time)
        );
  
        setPredictions(sortedPredictions);
        setFilteredPredictions(sortedPredictions);
  
        // Fetch the NFL Score
        const nflResponse = await axios.get(`https://ttg.nos.plus/api/nfl/leaderboard`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        // console.log('NFL Response:', nflResponse.data);
  
        const nflUser = nflResponse.data.leaderboard.find(
          (leader) => leader.username === userResponse.data.user.username
        );
        setNflScore(nflUser ? nflUser.points : 0);
  
        // Fetch the CL Score
        const clResponse = await axios.get(`https://ttg.nos.plus/api/cl/leaderboard`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        // console.log('CL Response:', clResponse.data);
  
        const clUser = clResponse.data.leaderboard.find(
          (leader) => leader.username === userResponse.data.user.username
        );
        setClScore(clUser ? clUser.points : 0);
  
        // Fetch the recent activities for the user
        const activitiesResponse = await axios.get(`https://ttg.nos.plus/api/user/${userId}/recent-activities`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        // console.log('Activities Response:', activitiesResponse.data);
  
        setActivities(activitiesResponse.data || []); // Set the activities state
  
      } catch (error) {
        // console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchUserData();
  }, [userId]);  

  const normalizeMatchData = (match) => {
    let whenValue;
    let parsedMatchTime;
  
    if (match.matchStatus === 'Finished') {
      parsedMatchTime = new Date(match.match_time);
      whenValue = calculateTimeUntil(parsedMatchTime);
    } else {
      const matchTimeStr = `${match.dateEvent}T${match.strTime}.000Z`;
      parsedMatchTime = new Date(matchTimeStr);
  
      if (isNaN(parsedMatchTime.getTime())) {
        return {
          ...match,
          when: 'Unknown',
          status: 'Open',
        };
      }
  
      whenValue = calculateTimeUntil(parsedMatchTime);
    }
  
    const homeScore = match.intHomeScore || match.home_score;
    const awayScore = match.intAwayScore || match.away_score;
    const homeTeam = match.strHomeTeam || match.home_team;
    const awayTeam = match.strAwayTeam || match.away_team;
  
    const winner = determineWinner(homeScore, awayScore);
    const predictionStatus =
      match.matchStatus === 'Finished'
        ? isPredictionCorrect(winner, match.userPrediction)
          ? 'Correct'
          : 'Incorrect'
        : 'Open';
  
    // Update to show 'Not Started' instead of 'NS'
    const displayStatus = match.matchStatus === 'NS' ? 'Not Started' : match.matchStatus;
  
    return {
      ...match,
      strHomeTeamBadge: match.home_team_badge || match.strHomeTeamBadge,
      strAwayTeamBadge: match.away_team_badge || match.strAwayTeamBadge,
      strHomeTeam: homeTeam,
      strAwayTeam: awayTeam,
      intHomeScore: homeScore,
      intAwayScore: awayScore,
      status: predictionStatus,
      when: whenValue,
      matchStatus: displayStatus,  // Use the updated status here
    };
  };  

  const determineWinner = (homeScore, awayScore) => {
    const home = parseInt(homeScore, 10);
    const away = parseInt(awayScore, 10);

    if (isNaN(home) || isNaN(away)) return null;
    if (home > away) return 'home';
    if (away > home) return 'away';
    return 'draw';
  };

  const isPredictionCorrect = (winner, userPrediction) => {
    return winner === userPrediction;
  };

  const determineHighlightClass = (prediction) => {
    if (prediction.matchStatus === 'Finished') {
      if (prediction.status === 'Correct') {
        return `${styles.correct} ${styles.correctHover}`;
      } else if (prediction.status === 'Incorrect') {
        return `${styles.incorrect} ${styles.incorrectHover}`;
      }
    }
    if (prediction.matchStatus === 'Upcoming' || prediction.matchStatus === 'Live') {
      return styles.openHover;
    }
    return '';
  };

  const calculateTimeUntil = (matchTime) => {
    const now = new Date();
    const matchDate = new Date(matchTime);

    const diffInSeconds = (matchDate - now) / 1000;

    if (isNaN(diffInSeconds)) {
      return 'Unknown';
    }

    const daysDifference = Math.floor(diffInSeconds / (3600 * 24));

    if (diffInSeconds < 0) {
      // For past matches
      if (daysDifference <= -1) {
        return `${-daysDifference} day${-daysDifference > 1 ? 's' : ''} ago`;
      } else {
        const hoursAgo = Math.floor(-diffInSeconds / 3600);
        return `${hoursAgo} hour${hoursAgo > 1 ? 's' : ''} ago`;
      }
    } else {
      // For future matches
      if (daysDifference >= 1) {
        return `in ${daysDifference} day${daysDifference > 1 ? 's' : ''}`;
      } else {
        const hoursUntil = Math.floor(diffInSeconds / 3600);
        const minutesUntil = Math.floor((diffInSeconds % 3600) / 60);
        return hoursUntil > 0
          ? `in ${hoursUntil} hour${hoursUntil > 1 ? 's' : ''} & ${minutesUntil} minute${minutesUntil > 1 ? 's' : ''}`
          : `in ${minutesUntil} minute${minutesUntil > 1 ? 's' : ''}`;
      }
    }
  };

  useEffect(() => {
    const filterPredictions = () => {
        let filtered = [...predictions];

        // Apply filters based on status
        if (selectedFilter !== 'All') {
            filtered = filtered.filter((prediction) => {
                if (selectedFilter === 'Correct') return prediction.status === 'Correct';
                if (selectedFilter === 'Incorrect') return prediction.status === 'Incorrect';
                if (selectedFilter === 'Open') return prediction.status === 'Open';
                return true;
            });
        }

        // Apply filters based on league
        if (selectedLeague !== 'All') {
            filtered = filtered.filter((prediction) => prediction.league === selectedLeague);
        }

        // Apply filters based on match status
        if (selectedStatus !== 'All') {
            filtered = filtered.filter((prediction) => {
                if (selectedStatus === 'Finished') return prediction.matchStatus === 'Finished';
                if (selectedStatus === 'Live') return prediction.matchStatus === 'Live';
                if (selectedStatus === 'Upcoming') return prediction.matchStatus === 'NS'; // Use the correct status for upcoming matches
                return true;
            });
        }

        // Sort by actual match time (ISO date string) for consistent ordering
        filtered.sort((a, b) => {
            const dateA = new Date(a.match_time || a.matchTime); // Use either match_time or matchTime
            const dateB = new Date(b.match_time || b.matchTime);

            return selectedSortOrder === 'asc' ? dateA - dateB : dateB - dateA;
        });

        setFilteredPredictions(filtered);
        setCurrentPage(1);
    };

    filterPredictions();
}, [selectedFilter, selectedLeague, selectedStatus, selectedSortOrder, predictions]);


  const handleMatchClick = async (match) => {
    if (match.error) {
      // console.log('Cannot fetch match details:', match.error);
    } else {
      setLoadingMatchDetails(true);
      setSelectedMatch(null);

      try {
        const matchResponse = await axios.get(`/api/cl/matches/${match.match_id}`);
        // console.log('Match Details Response:', matchResponse.data);
        setSelectedMatch(matchResponse.data);
      } catch (error) {
        // console.error('Error fetching full match details:', error);
      } finally {
        setLoadingMatchDetails(false);
      }
    }
  };

  const handleCloseModal = () => {
    setSelectedMatch(null);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const paginatedPredictions = filteredPredictions.slice(
    (currentPage - 1) * predictionsPerPage,
    currentPage * predictionsPerPage
  );

  const totalPages = Math.ceil(filteredPredictions.length / predictionsPerPage);

  const determineTeamClass = (team, homeScore, awayScore) => {
    const home = parseInt(homeScore, 10);
    const away = parseInt(awayScore, 10);

    if (team === 'home') {
      return home > away ? `font-bold ${styles.highlight}` : styles.text;
    } else if (team === 'away') {
      return away > home ? `font-bold ${styles.highlight}` : styles.text;
    } else {
      return styles.text;
    }
  };

  const renderTeamNameWithScore = (teamName, score) => {
    return score !== null && score !== undefined ? `${teamName} (${score})` : teamName;
  };

  if (loading) {
    return <LoadingSpinner theme={theme} />;
  }

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <div className={`min-h-screen ${styles.bg} p-4`}>
      <div className={`max-w-full sm:max-w-7xl mx-auto ${styles.cardBg} shadow-lg rounded-lg p-4`}>
        {/* Profile Card with Thick Bottom Border */}
        <div className={`sticky top-0 z-10 p-4 rounded-lg ${styles.cardBg} ${styles.thickBottomBorder} ${styles.shadow} mb-6`}>
          <div className="flex items-center">
            <img
              src={user.profile_picture || '/default-profile.png'}
              alt={user.username}
              className="w-16 h-16 rounded-full mr-4"
            />
            <div>
              <h2 className={`text-xl font-bold ${styles.highlight}`}>{user.name}</h2>
              <a
                href={`https://twitter.com/${user.username}`}
                target="_blank"
                rel="noopener noreferrer"
                className={`text-blue-500 hover:underline ${styles.text}`}
              >
                @{user.username}
              </a>
              <p className={`mt-2 text-sm ${styles.userText}`}>{user.description}</p>
            </div>
          </div>
          <div className="mt-4 flex justify-between text-sm">
            <p className={`font-semibold ${styles.text}`}>NFL Score: {nflScore}</p>
            <p className={`font-semibold ${styles.text}`}>CL Score: {clScore}</p>
          </div>
        </div>
  
        {/* Filter Section */}
        <Disclosure>
          {({ open }) => (
            <div
              className={`rounded-lg ${open ? 'shadow-lg' : ''} mb-6 ${
                theme === 'dark'
                  ? 'shadow-white/50'
                  : theme === 'sunset'
                  ? 'shadow-[#64ffda]/50'
                  : 'shadow-gray-500/50'
              }`}
            >
              <Disclosure.Button
                className={`flex justify-between w-full p-4 text-sm font-medium ${
                  theme === 'dark'
                    ? `bg-black text-white ${open ? 'rounded-t-lg' : 'rounded-lg'}`
                    : theme === 'sunset'
                    ? `bg-gray-800 text-[#64ffda] ${open ? 'rounded-t-lg' : 'rounded-lg'}`
                    : 'bg-white text-black shadow-lg shadow-gray-500/50 rounded-lg'
                }`}
                style={{
                  borderBottomLeftRadius: open ? '0' : '0.5rem',
                  borderBottomRightRadius: open ? '0' : '0.5rem',
                }}
              >
                <span>Filters</span>
                <ChevronDownIcon
                  className={`${open ? 'transform rotate-180' : ''} w-5 h-5`}
                />
              </Disclosure.Button>
              <Disclosure.Panel
                className={`p-4 rounded-b-lg ${
                  theme === 'dark'
                    ? 'bg-black text-white'
                    : theme === 'sunset'
                    ? 'bg-gray-800 text-[#64ffda]'
                    : 'bg-white text-black'
                }`}
              >
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
                  <select
                    className={`p-2 border rounded-lg focus:outline-none focus:ring w-full sm:w-auto ${
                      theme === 'dark'
                        ? 'border-gray-700 bg-black text-white'
                        : theme === 'sunset'
                        ? 'border-gray-700 bg-gray-800 text-white'
                        : 'border-gray-200 bg-white'
                    }`}
                    value={selectedFilter}
                    onChange={(e) => setSelectedFilter(e.target.value)}
                  >
                    <option value="All">All (Status)</option>
                    <option value="Correct">Correct (Status)</option>
                    <option value="Incorrect">Incorrect (Status)</option>
                    <option value="Open">Open (Status)</option>
                  </select>
                  <select
                    className={`p-2 border rounded-lg focus:outline-none focus:ring w-full sm:w-auto ${
                      theme === 'dark'
                        ? 'border-gray-700 bg-black text-white'
                        : theme === 'sunset'
                        ? 'border-gray-700 bg-gray-800 text-white'
                        : 'border-gray-200 bg-white'
                    }`}
                    value={selectedLeague}
                    onChange={(e) => setSelectedLeague(e.target.value)}
                  >
                    <option value="All">All (League)</option>
                    <option value="NFL">NFL (League)</option>
                    <option value="UEFA Champions League">
                      Champions League (League)
                    </option>
                  </select>
                  <select
                    className={`p-2 border rounded-lg focus:outline-none focus:ring w-full sm:w-auto ${
                      theme === 'dark'
                        ? 'border-gray-700 bg-black text-white'
                        : theme === 'sunset'
                        ? 'border-gray-700 bg-gray-800 text-white'
                        : 'border-gray-200 bg-white'
                    }`}
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                  >
                    <option value="All">All (Match Status)</option>
                    <option value="Finished">Finished (Match Status)</option>
                    <option value="Live">Live (Match Status)</option>
                    <option value="Upcoming">Upcoming (Match Status)</option>
                  </select>
                  <select
                    className={`p-2 border rounded-lg focus:outline-none focus:ring w-full sm:w-auto ${
                      theme === 'dark'
                        ? 'border-gray-700 bg-black text-white'
                        : theme === 'sunset'
                        ? 'border-gray-700 bg-gray-800 text-white'
                        : 'border-gray-200 bg-white'
                    }`}
                    value={selectedSortOrder}
                    onChange={(e) => setSelectedSortOrder(e.target.value)}
                  >
                    <option value="desc">Date: Newest First</option>
                    <option value="asc">Date: Oldest First</option>
                  </select>
                </div>
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>  


        <Tab.Group>
  <Tab.List className={`flex space-x-1 p-1 rounded-lg ${styles.cardBg} ${styles.border} shadow`} role="tablist">
    <Tab
      className={({ selected }) =>
        selected
          ? `${styles.highlight} ${styles.bg} px-4 py-2 text-sm font-semibold rounded-lg shadow focus:outline-none`
          : `${styles.text} ${styles.cardBg} px-4 py-2 text-sm font-semibold rounded-lg hover:${styles.hover} focus:outline-none`
      }
    >
      Predictions
    </Tab>
    <Tab
      className={({ selected }) =>
        selected
          ? `${styles.highlight} ${styles.bg} px-4 py-2 text-sm font-semibold rounded-lg shadow focus:outline-none`
          : `${styles.text} ${styles.cardBg} px-4 py-2 text-sm font-semibold rounded-lg hover:${styles.hover} focus:outline-none`
      }
    >
      Streaks
    </Tab>
    <Tab
      className={({ selected }) =>
        selected
          ? `${styles.highlight} ${styles.bg} px-4 py-2 text-sm font-semibold rounded-lg shadow focus:outline-none`
          : `${styles.text} ${styles.cardBg} px-4 py-2 text-sm font-semibold rounded-lg hover:${styles.hover} focus:outline-none`
      }
    >
      Badges
    </Tab>
    <Tab
      className={({ selected }) =>
        selected
          ? `${styles.highlight} ${styles.bg} px-4 py-2 text-sm font-semibold rounded-lg shadow focus:outline-none`
          : `${styles.text} ${styles.cardBg} px-4 py-2 text-sm font-semibold rounded-lg hover:${styles.hover} focus:outline-none`
      }
    >
      Recent Activity
    </Tab>
  </Tab.List>
  <Tab.Panels className="mt-4">
    <Tab.Panel>
      <div className="lg:hidden">
        <ul className="space-y-4">
          {paginatedPredictions.map((prediction, index) => (
            <li
              key={`${prediction.match_id}-${index}`}
                            className={`p-4 border rounded-lg cursor-pointer ${determineHighlightClass(prediction)} ${styles.border}`}
              onClick={() => handleMatchClick(prediction)}
            >
              <div className="flex justify-between">
                <div className="flex flex-col items-start">
                  <div className="flex items-center">
                    <img
                      src={prediction.strHomeTeamBadge}
                      alt={prediction.strHomeTeam}
                      className="w-8 h-8 mr-2"
                    />
                    <span className={determineTeamClass('home', prediction.intHomeScore, prediction.intAwayScore)}>
                      {renderTeamNameWithScore(prediction.strHomeTeam, prediction.intHomeScore)}
                    </span>
                  </div>
                  <div className="flex items-center mt-2">
                    <img
                      src={prediction.strAwayTeamBadge}
                      alt={prediction.strAwayTeam}
                      className="w-8 h-8 mr-2"
                    />
                    <span className={determineTeamClass('away', prediction.intHomeScore, prediction.intAwayScore)}>
                      {renderTeamNameWithScore(prediction.strAwayTeam, prediction.intAwayScore)}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col items-end">
                  <span className={`text-right ${styles.text}`}>{prediction.userPrediction}</span>
                  {prediction.matchStatus === 'Live' && (
                    <span className="ml-2 text-red-500 font-bold">
                      <span className="inline-block w-2 h-2 bg-red-500 rounded-full mr-1"></span>
                      LIVE
                    </span>
                  )}
                </div>
              </div>
              <div className={`mt-2 text-sm ${styles.text}`}>{prediction.when}</div>
            </li>
          ))}
        </ul>
      </div>

      <div className="hidden lg:block">
        <div className={`grid grid-cols-12 gap-4 font-semibold ${styles.text}`}>
          <span className="col-span-3">Home Team</span>
          <span className="col-span-3">Away Team</span>
          <span className="col-span-2 text-center">Prediction</span>
          <span className="col-span-2 text-center">Status</span>
          <span className="col-span-2 text-right">When?</span>
        </div>
        <ul className="mt-4 space-y-4">
          {paginatedPredictions.map((prediction, index) => (
            <li
              key={`${prediction.match_id}-${index}`}
              className={`grid grid-cols-12 gap-4 p-4 border rounded-lg cursor-pointer ${determineHighlightClass(prediction)} ${styles.border}`}
              onClick={() => handleMatchClick(prediction)}
            >
              <div className="flex items-center col-span-3">
                <img
                  src={prediction.strHomeTeamBadge}
                  alt={prediction.strHomeTeam}
                  className="w-8 h-8 mr-2"
                />
                <span className={determineTeamClass('home', prediction.intHomeScore, prediction.intAwayScore)}>
                  {renderTeamNameWithScore(prediction.strHomeTeam, prediction.intHomeScore)}
                </span>
              </div>
              <div className="flex items-center col-span-3">
                <img
                  src={prediction.strAwayTeamBadge}
                  alt={prediction.strAwayTeam}
                  className="w-8 h-8 mr-2"
                />
                <span className={determineTeamClass('away', prediction.intHomeScore, prediction.intAwayScore)}>
                  {renderTeamNameWithScore(prediction.strAwayTeam, prediction.intAwayScore)}
                </span>
              </div>
              <div className="col-span-2 text-center">
                <span className={`text-right ${styles.text}`}>{prediction.userPrediction}</span>
              </div>
              <div className="col-span-2 text-center">
                <span className={styles.text}>{prediction.matchStatus}</span>
              </div>
              <div className="col-span-2 text-right">
                <span className={styles.text}>{prediction.when}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="flex justify-center mt-6">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={`mx-2 px-4 py-2 border rounded ${
              currentPage === index + 1 ? `${styles.highlight} bg-opacity-80` : `${styles.border} ${styles.cardBg}`
            } ${styles.text}`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </Tab.Panel>

    <Tab.Panel>
      <Streaks 
        currentStreak={calculateStreaks(predictions).currentStreak}
        longestCorrectStreak={calculateStreaks(predictions).longestCorrectStreak}
        longestIncorrectStreak={calculateStreaks(predictions).longestIncorrectStreak}
        theme={theme} 
      />
    </Tab.Panel>

    <Tab.Panel>
      <div className="animate-fadeIn">
        <Badges userId={userId} theme={theme} />
      </div>
    </Tab.Panel>

    <Tab.Panel>
  <RecentActivity 
    userId={userId}  // Pass userId as a prop
    theme={theme} 
    isActive={true}  
  />
</Tab.Panel>
  </Tab.Panels>
</Tab.Group>

      </div>

      {selectedMatch && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    {loadingMatchDetails ? (
      <LoadingSpinner theme={theme} />
    ) : (
      <MatchDetail
        match={selectedMatch}
        onClose={() => setSelectedMatch(null)}
        theme={theme}
        league={selectedMatch.league === 'UEFA Champions League' ? 'cl' : selectedMatch.league || 'Unknown League'}
      />
    )}
  </div>
)}

{!selectedMatch && loadingMatchDetails && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <LoadingSpinner theme={theme} />
  </div>
)}

    </div>
  );
};

const calculateStreaks = (predictions) => {
  let currentStreak = 0;
  let longestCorrectStreak = 0;
  let longestIncorrectStreak = 0;
  let correctStreak = 0;
  let incorrectStreak = 0;
  let lastStreakStatus = null;

  predictions.forEach((prediction) => {
    if (prediction.status === 'Correct') {
      if (lastStreakStatus === 'Correct') {
        correctStreak += 1;
      } else {
        correctStreak = 1;
        lastStreakStatus = 'Correct';
      }
      incorrectStreak = 0;
      longestCorrectStreak = Math.max(longestCorrectStreak, correctStreak);
    } else if (prediction.status === 'Incorrect') {
      if (lastStreakStatus === 'Incorrect') {
        incorrectStreak += 1;
      } else {
        incorrectStreak = 1;
        lastStreakStatus = 'Incorrect';
      }
      correctStreak = 0;
      longestIncorrectStreak = Math.max(longestIncorrectStreak, incorrectStreak);
    } else {
      correctStreak = 0;
      incorrectStreak = 0;
    }
  });

  currentStreak = Math.max(correctStreak, incorrectStreak);

  return { currentStreak, longestCorrectStreak, longestIncorrectStreak };
};

export default ProfilePage;

