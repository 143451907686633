// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot
import './styles/index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext'; // Import AuthProvider

// Function to apply the scrollbar styles based on the theme
const applyScrollbarStyles = (theme) => {
  const styleElement = document.getElementById('custom-scrollbar-styles');
  if (styleElement) {
    styleElement.remove(); // Remove existing style element if any
  }

  const styles = {
    light: {
      scrollbarThumb: '#064e3b',
      scrollbarTrack: '#e5e5e5',
    },
    dark: {
      scrollbarThumb: '#606060',
      scrollbarTrack: '#333333',
    },
    sunset: {
      scrollbarThumb: '#64ffda',
      scrollbarTrack: '#404040',
    },
  };

  const themeStyles = styles[theme] || styles.light;

  const newStyleElement = document.createElement('style');
  newStyleElement.id = 'custom-scrollbar-styles';
  newStyleElement.innerHTML = `
    ::-webkit-scrollbar {
      width: 12px;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${themeStyles.scrollbarThumb};
      border-radius: 6px;
    }

    ::-webkit-scrollbar-track {
      background-color: ${themeStyles.scrollbarTrack};
    }

    /* Remove scrollbar arrows */
    ::-webkit-scrollbar-button {
      display: none;
      height: 0;
      width: 0;
    }

    body, .modal-content {
      scrollbar-color: ${themeStyles.scrollbarThumb} ${themeStyles.scrollbarTrack};
      scrollbar-width: thin;
    }
  `;
  document.head.appendChild(newStyleElement);
};

// Function to apply the initial theme and scrollbar styles
const applyInitialTheme = () => {
  const storedTheme = localStorage.getItem('theme') || 'light';
  document.body.classList.add(`theme-${storedTheme}`);
  applyScrollbarStyles(storedTheme);
};

// Apply the theme and scrollbar styles before rendering anything
applyInitialTheme();

const container = document.getElementById('root');
const root = createRoot(container); // Create a root

root.render(
  <Router> {/* Ensure Router wraps around AuthProvider */}
    <AuthProvider> {/* Wrap the App with AuthProvider */}
      <App applyScrollbarStyles={applyScrollbarStyles} />
    </AuthProvider>
  </Router>
);
