import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import Helmet for managing meta tags
import Navbar from './components/Navbar';
import NFLMatchesPage from './pages/NFLMatchesPage';
import ChampionsLeagueMatchesPage from './pages/ChampionsLeagueMatchesPage';
import LeaderboardPage from './pages/LeaderboardPage';
import SettingsPage from './pages/SettingsPage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import ResultsPage from './pages/ResultsPage';
import AuthCallback from './pages/AuthCallback';
import UserProfilePage from './pages/UserProfilePage';
import LiveActivitiesPage from './pages/LiveActivitiesPage';
import FAQPage from './pages/FAQPage';
import Footer from './components/Footer'; // Import the Footer component
import TOSPage from './pages/TOSPage'; // Import the TOSPage component
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'; // Import the PrivacyPolicyPage component
import './styles/App.css';

function App({ applyScrollbarStyles }) {
  const [theme, setTheme] = useState(() => {
    const storedTheme = localStorage.getItem('theme');
    return storedTheme || 'light';
  });

  const [isNFL, setIsNFL] = useState(() => localStorage.getItem('isNFL') === 'true');
  const location = useLocation();

  useEffect(() => {
    document.body.classList.remove('theme-light', 'theme-dark', 'theme-sunset');
    document.body.classList.add(`theme-${theme}`);
    applyScrollbarStyles(theme);
  }, [theme, applyScrollbarStyles]);

  const toggleTheme = (newTheme) => {
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  const handleSportToggle = (newIsNFL) => {
    setIsNFL(newIsNFL);
    localStorage.setItem('isNFL', newIsNFL);
  };

  return (
    <div className={theme}>
      <Helmet>
        <meta name="description" content="Confidently predict NFL and Champions League outcomes and compete with others to win $NOS and exciting prizes—all for free. Proudly serving as a public good for the Nosana community." />
        <meta property="og:title" content="NOS Predictions" />
        <meta property="og:description" content="Confidently predict NFL and Champions League outcomes and compete with others to win $NOS and exciting prizes—all for free. Proudly serving as a public good for the Nosana community." />
        <meta property="og:image" content="https://ttg.nos.plus/banners.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content={`https://ttg.nos.plus${location.pathname}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="NOS Predictions" />
        <meta name="twitter:description" content="Confidently predict NFL and Champions League outcomes and compete with others to win $NOS and exciting prizes—all for free. Proudly serving as a public good for the Nosana community." />
        <meta name="twitter:image" content="https://ttg.nos.plus/banners.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta charSet="utf-8" />
        <title>NOS Predictions</title>
      </Helmet>

      <Navbar theme={theme} toggleTheme={toggleTheme} toggleSport={handleSportToggle} isNFL={isNFL} />
      <Routes>
        <Route path="/" element={<Navigate replace to="/cl/Matches" />} />
        <Route path="/nfl/Matches" element={<NFLMatchesPage theme={theme} />} />
        <Route path="/cl/Matches" element={<ChampionsLeagueMatchesPage theme={theme} />} />
        <Route path="/nfl/Results" element={<ResultsPage league="nfl" theme={theme} />} />
        <Route path="/cl/Results" element={<ResultsPage league="cl" theme={theme} />} />
        <Route path="/nfl/Leaderboard" element={<LeaderboardPage league="nfl" theme={theme} isNFL={isNFL} />} />
        <Route path="/cl/Leaderboard" element={<LeaderboardPage league="cl" theme={theme} isNFL={isNFL} />} />
        <Route path="/settings" element={<SettingsPage theme={theme} />} />
        <Route path="/login" element={<LoginPage theme={theme} />} />
        <Route path="/register" element={<RegisterPage theme={theme} />} />
        <Route path="/auth/callback" element={<AuthCallback />} />
        <Route path="/profile/:userId" element={<UserProfilePage theme={theme} />} />
        <Route path="/live-activities" element={<LiveActivitiesPage theme={theme} />} />
        <Route path="/faq" element={<FAQPage theme={theme} />} />
        <Route path="/terms-of-service" element={<TOSPage theme={theme} />} /> {/* Add TOS route */}
        <Route path="/privacy-policy" element={<PrivacyPolicyPage theme={theme} />} /> {/* Add Privacy Policy route */}
      </Routes>
      <Footer theme={theme} /> {/* Include Footer component */}
    </div>
  );
}

export default App;
