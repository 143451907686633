import React, { useState } from 'react';

const RegisterPage = ({ theme }) => {
  const [loading, setLoading] = useState(false);

  const themeStyles = {
    light: {
      bg: 'bg-gray-100',
      text: 'text-gray-900',
      button: 'bg-blue-500 text-white hover:bg-blue-700',
    },
    dark: {
      bg: 'bg-black',
      text: 'text-white',
      button: 'bg-gray-700 text-white hover:bg-gray-900',
    },
    sunset: {
      bg: 'bg-gray-900',
      text: 'text-gray-400',
      button: 'bg-[#64ffda] text-black hover:bg-[#52d6b5]',
    },
  };

  const styles = themeStyles[theme] || themeStyles.light;

  const LoadingSpinner = ({ theme }) => {
    const themeStyles = {
      light: {
        overlay: 'bg-gray-100 bg-opacity-75',
      },
      dark: {
        overlay: 'bg-black bg-opacity-75',
      },
      sunset: {
        overlay: 'bg-gray-900 bg-opacity-75',
      },
    };

    const styles = themeStyles[theme] || themeStyles.light;

    return (
      <div className={`fixed inset-0 flex items-center justify-center ${styles.overlay}`}>
        <div className="w-16 h-16 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  };

  const handleTwitterRegister = () => {
    setLoading(true);
    window.location.href = 'https://ttg.nos.plus/api/auth/twitter';
  };

  if (loading) {
    return <LoadingSpinner theme={theme} />;
  }

  return (
    <div className={`flex flex-col items-center justify-center min-h-screen ${styles.bg}`}>
      <h1 className={`text-4xl font-bold mb-6 ${styles.text}`}>Register</h1>
      <button
        className={`${styles.button} px-6 py-3 rounded-lg transition`}
        onClick={handleTwitterRegister}
      >
        Register with Twitter
      </button>
    </div>
  );
};

export default RegisterPage;
