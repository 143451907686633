import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext';
import dayjs from 'dayjs';

const themeStyles = {
  light: {
    bg: 'bg-gray-100',
    text: 'text-gray-900',
    cardBg: 'bg-white',
    border: 'border-gray-200',
    focusBorder: 'border-blue-500',
    placeholder: 'text-gray-500',
    winningsCardBg: 'bg-white',
    winningsText: 'text-gray-800',
    winningsButton: 'bg-blue-500 text-white hover:bg-blue-600',
  },
  dark: {
    bg: 'bg-black',
    text: 'text-white',
    cardBg: 'bg-[#121212]',
    border: 'border-gray-600',
    focusBorder: 'border-blue-500',
    placeholder: 'text-gray-400',
    winningsCardBg: 'bg-[#1c1c1c]',
    winningsText: 'text-gray-300',
    winningsButton: 'bg-blue-500 text-white hover:bg-blue-400',
  },
  sunset: {
    bg: 'bg-gradient-to-br from-gray-900 to-gray-800',
    text: 'text-gray-400',
    cardBg: 'bg-gray-800',
    border: 'border-[#64ffda]',
    focusBorder: 'border-blue-500',
    placeholder: 'text-gray-500',
    winningsCardBg: 'bg-gray-700',
    winningsText: 'text-[#64ffda]',
    winningsButton: 'bg-teal-500 text-white hover:bg-teal-400',
  },
};

const SettingsPage = ({ theme }) => {
  const { user } = useContext(AuthContext);
  const [solanaAddress, setSolanaAddress] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState('');
  const [messageColor, setMessageColor] = useState('');
  const [loading, setLoading] = useState(true);
  const [remainingTime, setRemainingTime] = useState('');
  const [winnings, setWinnings] = useState([]);
  const [newWinnings, setNewWinnings] = useState({ userId: '', amount: '', currency: '', transactionLink: '', date: '' });
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchSolanaAddress = async () => {
      try {
        const response = await axios.get(`/api/auth/solana-address/${user.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        });

        if (response.data.solana_address) {
          setSolanaAddress(response.data.solana_address);
          const lastChangeDate = dayjs(response.data.change_date);
          const nextAllowedUpdate = lastChangeDate.add(7, 'day');
          const now = dayjs();

          if (now.isBefore(nextAllowedUpdate)) {
            const diff = nextAllowedUpdate.diff(now, 'second');
            const days = Math.floor(diff / (24 * 3600));
            const hours = Math.floor((diff % (24 * 3600)) / 3600);
            setRemainingTime(`You can update your address again in ${days} days and ${hours} hours.`);
          }
        }
      } catch (error) {
        // console.error('Error fetching Solana address:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchWinnings = async () => {
      try {
        const response = await axios.get(`/api/auth/user-winnings/${user.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        });
        setWinnings(response.data.winnings);
      } catch (error) {
        // console.error('Error fetching winnings:', error);
      }
    };

    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/auth/users', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        });
        setUsers(response.data.users);
      } catch (error) {
        // console.error('Error fetching users:', error);
      }
    };

    if (user) {
      fetchSolanaAddress();
      fetchWinnings();
      if (user.id === 1) {
        fetchUsers();
      }
    }
  }, [user]);

  const handleSolanaAddressChange = async (e) => {
    e.preventDefault();
    if (remainingTime) {
      setMessage('You can only change your Solana address once per week.');
      setMessageColor('text-red-600');
      return;
    }

    try {
      const response = await axios.post(
        '/api/auth/update-solana-address',
        {
          userId: user.id,
          solanaAddress,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      );

      if (response.data.success) {
        setMessage('Solana address updated successfully.');
        setMessageColor('text-green-600');
        setIsEditing(false);
      } else {
        throw new Error(response.data.error);
      }
    } catch (error) {
      setMessage(error.message || 'An error occurred while updating your Solana address.');
      setMessageColor('text-red-600');
    }
  };

  const handleWinningsSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await axios.post(
        '/api/auth/add-winnings',
        {
          ...newWinnings,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      );
  
      if (response.data.success) {
        setMessage('Winnings added successfully.');
        setMessageColor('text-green-600');
        setNewWinnings({ userId: '', amount: '', currency: '', transactionLink: '', date: '' });
        // Refresh winnings
        const updatedWinnings = await axios.get(`/api/auth/user-winnings/${user.id}`);
        setWinnings(updatedWinnings.data.winnings);
      } else {
        throw new Error(response.data.message || 'An error occurred while adding winnings.');
      }
    } catch (error) {
      setMessage(error.message || 'An error occurred while adding winnings.');
      setMessageColor('text-red-600');
    }
  };

  if (loading) {
    return (
      <div className={`fixed inset-0 flex items-center justify-center ${theme === 'dark' ? 'bg-black bg-opacity-75' : theme === 'sunset' ? 'bg-gray-900 bg-opacity-75' : 'bg-gray-100 bg-opacity-75'}`}>
        <div className="w-16 h-16 border-t-4 border-b-4 border-blue-500 rounded-full animate-spin"></div>
      </div>
    );
  }

  const currentTheme = themeStyles[theme];

  return (
    <div className={`min-h-screen p-8 ${currentTheme.bg}`}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-7xl mx-auto">
        {/* Left Column: Welcome and Solana Form */}
        <div className={`rounded-lg p-8 ${currentTheme.cardBg} ${currentTheme.text}`}>
          <div className="flex flex-col items-center md:items-start">
            <img src={user.profile_picture} alt="Profile" className="rounded-full w-32 h-32 mb-4" />
            <h5 className="text-2xl font-bold mb-2">Welcome, {user.name}!</h5>
            <h6 className="mb-4 pb-8 text-gray-400">@{user.username}</h6>
            <form onSubmit={handleSolanaAddressChange} className="w-full relative">
              <div className="mb-4 relative">
                <input
                  type="text"
                  id="solanaAddress"
                  value={solanaAddress}
                  onChange={(e) => setSolanaAddress(e.target.value)}
                  className={`w-full px-4 py-2 rounded-md border-2 transition-all duration-300 ease-in-out focus:outline-none
                    ${isEditing ? currentTheme.focusBorder : currentTheme.border} 
                    ${currentTheme.cardBg} ${currentTheme.text}`}
                  disabled={!isEditing}
                  required
                />
                <label
                  htmlFor="solanaAddress"
                  className={`absolute left-4 top-2 transform transition-all duration-300 ease-in-out
                    ${solanaAddress ? '-top-6 text-xs' : 'top-2 text-base'}
                    ${currentTheme.text}`}
                  style={{
                    top: solanaAddress ? '-1.5rem' : '0.5rem',
                    fontSize: solanaAddress ? '0.75rem' : '1rem',
                  }}
                >
                  {isEditing ? 'Enter new Solana Address' : 'Solana Address'}
                </label>
              </div>
              {!isEditing && (
                <button
                  type="button"
                  onClick={() => setIsEditing(true)}
                  className="absolute right-4 top-2 text-xl cursor-pointer"
                >
                  ✏️
                </button>
              )}
              {isEditing && (
                <button
                  type="submit"
                  className="w-full bg-blue-500 text-white p-2 rounded-lg transition-transform duration-200 transform hover:scale-105"
                >
                  Update Solana Address
                </button>
              )}
            </form>
            {message && <p className={`mt-4 ${messageColor}`}>{message}</p>}
            {remainingTime && <p className="mt-4 text-red-600">{remainingTime}</p>}
          </div>
        </div>

        {/* Right Column: Winnings */}
        <div className={`rounded-lg p-8 ${currentTheme.cardBg} ${currentTheme.text}`}>
          <h3 className="text-xl font-bold mb-4">Your Winnings 🎉</h3>
          {winnings.length === 0 ? (
            <p>No winnings available.</p>
          ) : (
            <ul className="space-y-4">
              {winnings.map((winning) => (
                <li
                  key={winning.id}
                  className={`p-4 border rounded-lg ${currentTheme.winningsCardBg} shadow-md hover:shadow-lg transition-shadow duration-200`}
                >
                  <p className={`text-base md:text-lg ${currentTheme.winningsText}`}><span role="img" aria-label="money">💰</span> <strong>Amount:</strong> {winning.amount} {winning.currency}</p>
                  <p className={`text-base md:text-lg ${currentTheme.winningsText}`}><span role="img" aria-label="calendar">📅</span> <strong>Date:</strong> {dayjs(winning.date).format('YYYY-MM-DD')}</p>
                  <p className={`text-base md:text-lg ${currentTheme.winningsText}`}><span role="img" aria-label="link">🔗</span> <strong>Transaction:</strong></p>
                  <div className="mt-2 md:mt-0 md:inline-block md:ml-2">
                    <a
                      href={winning.transaction_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`inline-block mt-2 px-4 py-2 rounded ${currentTheme.winningsButton} text-sm font-semibold transition-transform duration-200 transform hover:scale-105`}
                    >
                      View Transaction
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {/* Admin Panel for Adding Winnings */}
      {user.id === 1 && (
        <div className="w-full max-w-7xl mt-10">
          <div className={`rounded-lg p-8 ${currentTheme.cardBg} ${currentTheme.text}`}>
            <h3 className="text-xl font-bold mb-4">Admin Panel - Add Winnings</h3>
            <form onSubmit={handleWinningsSubmit}>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">User</label>
                <select
                  value={newWinnings.userId}
                  onChange={(e) => setNewWinnings({ ...newWinnings, userId: e.target.value })}
                  className={`w-full px-4 py-2 rounded-md border-2 ${currentTheme.border} ${currentTheme.cardBg} ${currentTheme.text}`}
                  required
                >
                  <option value="">Select a user</option>
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name} ({user.username})
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Amount</label>
                <input
                  type="number"
                  value={newWinnings.amount}
                  onChange={(e) => setNewWinnings({ ...newWinnings, amount: e.target.value })}
                  className={`w-full px-4 py-2 rounded-md border-2 ${currentTheme.border} ${currentTheme.cardBg} ${currentTheme.text}`}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Currency</label>
                <input
                  type="text"
                  value={newWinnings.currency}
                  onChange={(e) => setNewWinnings({ ...newWinnings, currency: e.target.value })}
                  className={`w-full px-4 py-2 rounded-md border-2 ${currentTheme.border} ${currentTheme.cardBg} ${currentTheme.text}`}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Transaction Link</label>
                <input
                  type="url"
                  value={newWinnings.transactionLink}
                  onChange={(e) => setNewWinnings({ ...newWinnings, transactionLink: e.target.value })}
                  className={`w-full px-4 py-2 rounded-md border-2 ${currentTheme.border} ${currentTheme.cardBg} ${currentTheme.text}`}
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Date</label>
                <input
                  type="date"
                  value={newWinnings.date}
                  onChange={(e) => setNewWinnings({ ...newWinnings, date: e.target.value })}
                  className={`w-full px-4 py-2 rounded-md border-2 ${currentTheme.border} ${currentTheme.cardBg} ${currentTheme.text}`}
                  required
                />
              </div>
              <button type="submit" className="w-full bg-blue-500 text-white p-2 rounded-lg transition-transform duration-200 transform hover:scale-105">
                Add Winnings
              </button>
            </form>
            {message && <p className={`mt-4 ${messageColor}`}>{message}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsPage;
