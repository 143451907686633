import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';

const PrivacyPolicyPage = ({ theme }) => {
  const themeStyles = {
    light: {
      bg: 'bg-gray-100',
      text: 'text-gray-900',
      cardBg: 'bg-white',
      border: 'border-gray-200',
      userText: 'text-gray-600',
      highlight: 'text-[#064e3b]',
      hover: 'hover:bg-gray-100 hover:bg-opacity-50',
    },
    dark: {
      bg: 'bg-black',
      text: 'text-white',
      cardBg: 'bg-[#121212]',
      border: 'border-gray-600',
      userText: 'text-gray-400',
      highlight: 'text-white',
      hover: 'hover:bg-[#1c1c1c] hover:bg-opacity-70',
    },
    sunset: {
      bg: 'bg-gray-900',
      text: 'text-gray-400',
      cardBg: 'bg-gray-800',
      border: 'border-[#64ffda]',
      userText: 'text-[#64ffda]',
      highlight: 'text-[#52d6b5]',
      hover: 'hover:bg-gray-800 hover:bg-opacity-70',
    },
  };

  const styles = themeStyles[theme] || themeStyles.light;

  const privacyData = [
    {
      title: 'Introduction',
      content: `This Privacy Policy outlines what information is collected and how it is used on the Site.`,
    },
    {
      title: 'Information Collected',
      content: `When registering on the Site, the following public data from Twitter is collected and stored:
      - Name
      - Username
      - Bio
      - Profile Picture
      
      Predictions made on the Site are also collected and stored.`,
    },
    {
      title: 'Use of Information',
      content: `The information collected is used for the following purposes:
      - Facilitating participation in prediction activities.
      - Displaying predictions and related information on the Site.
      - Announcing winners on the Site and on the official Twitter account, @luzzo33.
      - Distributing prizes to the Solana wallet address provided.`,
    },
    {
      title: 'Data Sharing',
      content: `Personal information is not shared with third parties, except for the purposes mentioned above. Data is used strictly within the context of the Site and on the Twitter account to announce winners.`,
    },
    {
      title: 'Data Security',
      content: `Reasonable security measures are implemented to protect information from unauthorized access, use, or disclosure. However, no method of transmission over the internet is completely secure.`,
    },
    {
      title: 'User Rights',
      content: `Users have the right to:
      - Access the information held about them.
      - Request the correction of inaccurate information.
      - Request the deletion of their data (which may result in the termination of the account on the Site).
      
      To exercise these rights, contact can be made via email at luzzo@nos.plus.`,
    },
    {
      title: 'Changes to this Privacy Policy',
      content: `This Privacy Policy may be updated from time to time. Any changes will be posted on this page, and continued use of the Site constitutes acceptance of the updated Privacy Policy.`,
    },
    {
      title: 'Contact Information',
      content: `For any questions or concerns about this Privacy Policy, contact can be made via email at luzzo@nos.plus.`,
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className={`min-h-screen ${styles.bg} p-6`}>
      <div className={`max-w-4xl mx-auto p-6 rounded-lg ${styles.cardBg} ${styles.border} shadow-md`}>
        <h1 className={`text-3xl font-bold mb-4 ${styles.highlight}`}>Privacy Policy</h1>

        {privacyData.map((section, index) => (
          <div key={index} className="mb-6">
            <h2
              className={`text-2xl font-semibold cursor-pointer mb-2 flex items-center justify-between ${styles.text}`}
              onClick={() => toggleAccordion(index)}
            >
              {section.title}
              {openIndex === index ? (
                <ChevronUpIcon className="w-5 h-5" />
              ) : (
                <ChevronDownIcon className="w-5 h-5" />
              )}
            </h2>
            {openIndex === index && (
              <div className={`${styles.userText} transition-all duration-300 ease-in-out`}>
                <p>{section.content}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrivacyPolicyPage;
