import React from 'react';
import { motion } from 'framer-motion';
import { CheckCircleIcon, XCircleIcon, TrendingUpIcon } from '@heroicons/react/solid';

const Streaks = ({ currentStreak, longestCorrectStreak, longestIncorrectStreak, theme }) => {
  const themeStyles = {
    light: {
      bg: 'bg-gray-100',
      text: 'text-gray-900',
      cardBg: 'bg-white',
      border: 'border-gray-200',
      highlight: 'text-[#064e3b]',
      correct: 'bg-green-100',
      incorrect: 'bg-red-100',
    },
    dark: {
      bg: 'bg-black',
      text: 'text-white',
      cardBg: 'bg-[#121212]',
      border: 'border-gray-600',
      highlight: 'text-white',
      correct: 'bg-[#00440028]',
      incorrect: 'bg-[#44000028]',
    },
    sunset: {
      bg: 'bg-gray-900',
      text: 'text-gray-400',
      cardBg: 'bg-gray-800',
      border: 'border-[#64ffda]',
      highlight: 'text-[#52d6b5]',
      correct: 'bg-[#005f3728]',
      incorrect: 'bg-[#9b000028]',
    },
  };

  const styles = themeStyles[theme] || themeStyles.light;

  const streakVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={streakVariants}
      transition={{ duration: 0.5 }}
      className={`p-6 rounded-lg border ${styles.cardBg} ${styles.border} shadow-lg`}
    >
      <h3 className={`text-2xl font-bold mb-6 ${styles.text} ${styles.highlight}`}>
        📈 Your Streaks
      </h3>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Current Streak */}
        <motion.div
          initial="hidden"
          animate="visible"
          variants={streakVariants}
          transition={{ duration: 0.5 }}
          className={`p-4 rounded-lg flex items-center justify-between ${styles.cardBg} ${styles.border} shadow-md`}
        >
          <div>
            <h4 className={`text-lg font-semibold ${styles.text}`}>Current Streak</h4>
            <p className={`text-sm mt-1 ${styles.text}`}>
              {currentStreak > 0 ? (
                `${currentStreak} Prediction${currentStreak > 1 ? 's' : ''} in a row! 🎯`
              ) : (
                'No current streak 😕'
              )}
            </p>
          </div>
          <TrendingUpIcon className={`h-10 w-10 ${styles.highlight}`} />
        </motion.div>

        {/* Longest Correct Streak */}
        <motion.div
          initial="hidden"
          animate="visible"
          variants={streakVariants}
          transition={{ duration: 0.7 }}
          className={`p-4 rounded-lg flex items-center justify-between ${styles.cardBg} ${styles.border} shadow-md`}
        >
          <div>
            <h4 className={`text-lg font-semibold ${styles.text}`}>Longest Correct Streak</h4>
            <p className={`text-sm mt-1 ${styles.text}`}>
              {longestCorrectStreak > 0 ? (
                `${longestCorrectStreak} Correct Prediction${longestCorrectStreak > 1 ? 's' : ''} 🟢`
              ) : (
                'No correct streak yet 🙁'
              )}
            </p>
          </div>
          <CheckCircleIcon className={`h-10 w-10 ${styles.correct}`} />
        </motion.div>

        {/* Longest Incorrect Streak */}
        <motion.div
          initial="hidden"
          animate="visible"
          variants={streakVariants}
          transition={{ duration: 0.9 }}
          className={`p-4 rounded-lg flex items-center justify-between ${styles.cardBg} ${styles.border} shadow-md`}
        >
          <div>
            <h4 className={`text-lg font-semibold ${styles.text}`}>Longest Incorrect Streak</h4>
            <p className={`text-sm mt-1 ${styles.text}`}>
              {longestIncorrectStreak > 0 ? (
                `${longestIncorrectStreak} Incorrect Prediction${longestIncorrectStreak > 1 ? 's' : ''} 🔴`
              ) : (
                'No incorrect streak 🎉'
              )}
            </p>
          </div>
          <XCircleIcon className={`h-10 w-10 ${styles.incorrect}`} />
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Streaks;
