import React, { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';

const TOSPage = ({ theme }) => {
  const themeStyles = {
    light: {
      bg: 'bg-gray-100',
      text: 'text-gray-900',
      cardBg: 'bg-white',
      border: 'border-gray-200',
      userText: 'text-gray-600',
      highlight: 'text-[#064e3b]',
      hover: 'hover:bg-gray-100 hover:bg-opacity-50',
    },
    dark: {
      bg: 'bg-black',
      text: 'text-white',
      cardBg: 'bg-[#121212]',
      border: 'border-gray-600',
      userText: 'text-gray-400',
      highlight: 'text-white',
      hover: 'hover:bg-[#1c1c1c] hover:bg-opacity-70',
    },
    sunset: {
      bg: 'bg-gray-900',
      text: 'text-gray-400',
      cardBg: 'bg-gray-800',
      border: 'border-[#64ffda]',
      userText: 'text-[#64ffda]',
      highlight: 'text-[#52d6b5]',
      hover: 'hover:bg-gray-800 hover:bg-opacity-70',
    },
  };

  const styles = themeStyles[theme] || themeStyles.light;

  const tosData = [
    {
      title: 'Introduction',
      content: `By accessing or using this site ("Site"), agreement to comply with and be bound by these Terms of Service ("Terms") is assumed. If these Terms are not agreed upon, use of the Site must be discontinued.`,
    },
    {
      title: 'Eligibility',
      content: `Participation in predictions on the Site requires a Twitter account older than one month. Creating multiple accounts is strictly prohibited. Any user found creating multiple accounts will be banned from the Site.`,
    },
    {
      title: 'Predictions and Points',
      content: `Once registered, users can predict on NFL and/or Champions League matches for free. Each correct prediction earns 1 point. The total points will determine the position on the leaderboard. Prizes are awarded based on different timeframes, with criteria for these prizes potentially varying.`,
    },
    {
      title: 'Redeeming Prizes',
      content: `To redeem prizes, a Solana wallet address must be entered on the settings page. This address will be permanently bound to the account. Prizes will be sent directly to the Solana address provided. No other form of contact regarding prize distribution will be made. Winnings can be viewed under account settings, along with a transaction link.`,
    },
    {
      title: 'Cheating and Banning',
      content: `Cheating is taken very seriously. If there is any suspicion of cheating, the user may be banned from the Site immediately and without explanation.`,
    },
    {
      title: 'Prizes',
      content: `Prizes are not always announced beforehand and may change at any time. Decisions regarding prize distribution and eligibility are final and at the sole discretion of the Site administration.`,
    },
    {
      title: 'Data Usage',
      content: `By registering on the Site, users agree to provide access to their public Twitter data (including name, username, bio, and profile picture) and predictions. This data will be used solely for participation in the Site's activities and may be displayed on the Site and on the official Twitter account, @luzzo33, to announce winners.`,
    },
    {
      title: 'Amendments',
      content: `These Terms may be amended at any time. Any changes will be posted on this page, and continued use of the Site constitutes acceptance of the amended Terms.`,
    },
    {
      title: 'Contact Information',
      content: `For any inquiries regarding these Terms, contact can be made via email at luzzo@nos.plus.`,
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className={`min-h-screen ${styles.bg} p-6`}>
      <div className={`max-w-4xl mx-auto p-6 rounded-lg ${styles.cardBg} ${styles.border} shadow-md`}>
        <h1 className={`text-3xl font-bold mb-4 ${styles.highlight}`}>Terms of Service</h1>

        {tosData.map((section, index) => (
          <div key={index} className="mb-6">
            <h2
              className={`text-2xl font-semibold cursor-pointer mb-2 flex items-center justify-between ${styles.text}`}
              onClick={() => toggleAccordion(index)}
            >
              {section.title}
              {openIndex === index ? (
                <ChevronUpIcon className="w-5 h-5" />
              ) : (
                <ChevronDownIcon className="w-5 h-5" />
              )}
            </h2>
            {openIndex === index && (
              <div className={`${styles.userText} transition-all duration-300 ease-in-out`}>
                <p>{section.content}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TOSPage;
