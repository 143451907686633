import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Pie } from 'react-chartjs-2';
import { ClipLoader } from 'react-spinners';
import { FaChartBar, FaListAlt, FaHistory, FaCalendarAlt } from 'react-icons/fa';
import { AuthContext } from '../contexts/AuthContext';
import { motion } from 'framer-motion';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const themeStyles = {
  light: {
    bg: 'bg-gray-100',
    text: 'text-gray-900',
    cardBg: 'bg-white',
    border: 'border-gray-200',
    userText: 'text-gray-600',
    highlight: 'text-[#064e3b]',
    hover: 'hover:bg-gray-50 hover:bg-opacity-50',
    correct: 'rgba(76, 175, 80, 0.7)',
    incorrect: 'rgba(244, 67, 54, 0.7)',
    correctHover: 'rgba(129, 199, 132, 0.8)',
    incorrectHover: 'rgba(229, 115, 115, 0.8)',
    highlightUser: 'bg-green-50',
    secondaryCardBg: 'bg-gray-200',
    highlightAccent: '#00BFA6',
  },
  dark: {
    bg: 'bg-black',
    text: 'text-white',
    cardBg: 'bg-[#121212]',
    border: 'border-gray-600',
    userText: 'text-gray-400',
    highlight: 'text-white',
    hover: 'hover:bg-[#1c1c1c] hover:bg-opacity-70',
    correct: 'rgba(56, 142, 60, 0.7)',
    incorrect: 'rgba(211, 47, 47, 0.7)',
    correctHover: 'rgba(102, 187, 106, 0.8)',
    incorrectHover: 'rgba(239, 83, 80, 0.8)',
    highlightUser: 'bg-[#00220033]',
    secondaryCardBg: 'bg-[#1c1c1c]',
    highlightAccent: '#BB86FC',
  },
  sunset: {
    bg: 'bg-gray-900',
    text: 'text-gray-400',
    cardBg: 'bg-gray-800',
    border: 'border-[#64ffda]',
    userText: 'text-[#64ffda]',
    highlight: 'text-[#52d6b5]',
    hover: 'hover:bg-gray-700 hover:bg-opacity-60',
    correct: 'rgba(67, 160, 71, 0.7)',
    incorrect: 'rgba(229, 57, 53, 0.7)',
    correctHover: 'rgba(102, 187, 106, 0.8)',
    incorrectHover: 'rgba(239, 83, 80, 0.8)',
    highlightUser: 'bg-[#00311f33]',
    secondaryCardBg: 'bg-gray-700',
    highlightAccent: '#FF4081',
  },
};

const MatchDetail = ({ match, onClose, theme, league, onUpdatePrediction }) => {
  const { isAuthenticated, user } = useContext(AuthContext);
  const [additionalDetails, setAdditionalDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('overview');
  const [predictions, setPredictions] = useState([]);
  const [userPrediction, setUserPrediction] = useState(null);
  const [liveScore, setLiveScore] = useState(null);
  const [error, setError] = useState(null);
  const [showPredictionErrorModal, setShowPredictionErrorModal] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);

  const styles = themeStyles[theme] || themeStyles.light;

  useEffect(() => {
    const fetchAdditionalDetails = async () => {
      try {
        const response = await axios.get(`/api/${league}/matches/${match.id}`);
        // console.log('Additional Match Details:', response.data);
        setAdditionalDetails(response.data);
        setLoading(false);
      } catch (error) {
        // console.error('Error fetching additional match details:', error);
        setLoading(false);
      }
    };
    fetchAdditionalDetails();
  }, [match.id, league]);

  useEffect(() => {
    const fetchPredictions = async () => {
      try {
        const response = await axios.get(`/api/predictions/match/${match.id}/predictions`);
        // console.log('Predictions:', response.data);
        setPredictions(response.data);
        if (user) {
          const userPred = response.data.find((pred) => pred.user_id === user.id);
          setUserPrediction(userPred);
        }
      } catch (error) {
        // console.error('Error fetching predictions:', error);
      }
    };

    const fetchLiveScore = async () => {
      try {
        const leagueId = league === 'cl' ? '4480' : league === 'nfl' ? '4391' : null;
        if (!leagueId) return;
        const liveScoreApiUrl = `/api/livescores/${leagueId}`;
        const response = await axios.get(liveScoreApiUrl);
        // console.log('Live Score:', response.data);
        const liveMatch = response.data.livescore.find((liveMatch) => liveMatch.idEvent === match.id);
        setLiveScore(liveMatch);
      } catch (error) {
        // console.error('Error fetching live score:', error);
        setError('Error fetching live score.');
      }
    };

    if (activeTab === 'overview' || activeTab === 'predictions') {
      fetchPredictions();
      fetchLiveScore();
    }

    const intervalId = setInterval(fetchLiveScore, 60000);
    return () => clearInterval(intervalId);
  }, [activeTab, match.id, league, user]);

  const formatRelativeTime = (date) => {
    const now = new Date();
    const eventDate = new Date(date);
    const diff = now - eventDate;
    const diffInMinutes = Math.floor(diff / (1000 * 60));
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInWeeks = Math.floor(diffInDays / 7);
    const diffInMonths = Math.floor(diffInDays / 30);
    if (diffInMinutes < 60) return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
    if (diffInHours < 24) return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
    if (diffInDays < 7) return `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
    if (diffInWeeks < 4) return `${diffInWeeks} week${diffInWeeks !== 1 ? 's' : ''} ago`;
    return `${diffInMonths} month${diffInMonths !== 1 ? 's' : ''} ago`;
  };

  const getFutureTime = (date) => {
    const now = new Date();
    const eventDate = new Date(date);
    const diff = eventDate - now;
    if (diff <= 0) return formatRelativeTime(date);
    const diffInMinutes = Math.floor(diff / (1000 * 60));
    const diffInHours = Math.floor(diffInMinutes / 60);
    const diffInDays = Math.floor(diffInHours / 24);
    const diffInWeeks = Math.floor(diffInDays / 7);
    const diffInMonths = Math.floor(diffInDays / 30);
    if (diffInMinutes < 60) return `in ${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''}`;
    if (diffInHours < 24) return `in ${diffInHours} hour${diffInHours !== 1 ? 's' : ''}`;
    if (diffInDays < 7) return `in ${diffInDays} day${diffInDays !== 1 ? 's' : ''}`;
    if (diffInWeeks < 4) return `in ${diffInWeeks} week${diffInWeeks !== 1 ? 's' : ''}`;
    return `in ${diffInMonths} month${diffInMonths !== 1 ? 's' : ''}`;
  };

  const handlePredictionClick = async (e, prediction) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isAuthenticated) {
      setShowAuthModal(true);
      return;
    }
    if (!match.id || !user || !user.id) return;
    const matchDateTime = new Date(`${match.dateEvent}T${match.strTime}Z`).getTime();
    const currentTime = new Date().getTime();
    if (currentTime > matchDateTime) {
      setShowPredictionErrorModal(true);
      return;
    }
    const previousPrediction = userPrediction;
    setUserPrediction({ user_id: user.id, prediction });
    setPredictions((prevPredictions) => {
      const filteredPredictions = prevPredictions.filter((pred) => pred.user_id !== user.id);
      return [{ user_id: user.id, prediction }, ...filteredPredictions];
    });
    onUpdatePrediction(match.id, prediction);
    try {
      await axios.post('/api/predictions', {
        userId: user.id,
        matchId: match.id,
        prediction,
        league,
      });
    } catch (error) {
      // console.error('Error submitting prediction:', error);
      setUserPrediction(previousPrediction);
      setPredictions((prevPredictions) => prevPredictions);
      onUpdatePrediction(match.id, previousPrediction?.prediction || null);
    }
  };

  const getDotColors = (matches) => {
    return matches.map((lastMatch) => {
      const isHomeTeam = lastMatch.idHomeTeam === match.idHomeTeam;
      const homeScore = parseInt(lastMatch.intHomeScore);
      const awayScore = parseInt(lastMatch.intAwayScore);

      if (homeScore === awayScore) {
        return '#FFC107'; // Neutral color (Yellow) for draws
      }

      const isWin = (isHomeTeam && homeScore > awayScore) || (!isHomeTeam && awayScore > homeScore);
      return isWin ? styles.correct : styles.incorrect;
    });
  };

  const getOpponentBadge = (lastMatch, isHomeTeam) => {
    // Display the opponent's badge, not the team's badge
    return isHomeTeam ? lastMatch.strAwayTeamBadge : lastMatch.strHomeTeamBadge;
  };

  const renderOverview = () => {
    const matchDateTime = new Date(`${match.dateEvent}T${match.strTime}Z`);
    const matchTimeDisplay = matchDateTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const matchDateDisplay = matchDateTime.toLocaleDateString();
  
    const homeScore = liveScore ? liveScore.intHomeScore : match.intHomeScore;
    const awayScore = liveScore ? liveScore.intAwayScore : match.intAwayScore;
  
    // Update the logic to determine if the match is live based on both strStatus and strProgress
    const isMatchLive = liveScore && (liveScore.strStatus !== 'FT' && liveScore.strProgress !== 'Final');
    
    // Update match progress display
    const matchProgress = liveScore ? (isMatchLive ? `${liveScore.strStatus} - ${liveScore.strProgress}` : 'Final') : matchTimeDisplay;
  
    return (
      <motion.div
        className={`p-2 md:p-4 rounded-lg ${styles.cardBg} mb-4 shadow-md ${styles.border} ${
          isMatchLive ? 'border-red-500 shadow-lg shadow-red-500/50' : ''
        }`}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h3 className="text-2xl font-bold mb-4 text-center">Match Overview</h3>
        <div className="text-center mb-2">
          <p className="text-sm text-gray-600">{matchDateDisplay}</p>
        </div>
        <div className="grid grid-cols-3 gap-2 items-center text-center mb-2">
          <div className="flex flex-col items-center">
            <img
              src={match.strHomeTeamBadge}
              alt={match.strHomeTeam}
              className={`w-12 h-12 md:w-16 md:h-16 mb-2 cursor-pointer rounded-full ${
                userPrediction && userPrediction.prediction === 'home'
                  ? `shadow-lg border-2 ${
                      theme === 'dark'
                        ? 'border-white shadow-white/10'
                        : theme === 'sunset'
                        ? 'border-[#64ffda] shadow-[#64ffda]/10'
                        : 'border-[#064e3b] shadow-[#064e3b]/10'
                    }`
                  : ''
              } grow-animation`}
              onClick={(e) => !isMatchLive && handlePredictionClick(e, 'home')}
            />
            <h4 className={`text-xl font-semibold md:text-2xl ${styles.text}`}>{match.strHomeTeam}</h4>
            <p className={`text-lg md:text-xl font-bold ${styles.text}`}>{homeScore}</p>
          </div>
          <div className="flex flex-col items-center justify-center">
            <p
              className={`text-lg font-semibold cursor-pointer ${
                userPrediction && userPrediction.prediction === 'draw'
                  ? `shadow-lg border-2 rounded-full p-2 ${
                      theme === 'dark'
                        ? 'border-white shadow-white/10'
                        : theme === 'sunset'
                        ? 'border-[#64ffda] shadow-[#64ffda]/10'
                        : 'border-[#064e3b] shadow-[#064e3b]/10'
                    }`
                  : ''
              }`}
              onClick={(e) => !isMatchLive && handlePredictionClick(e, 'draw')}
            >
              VS
            </p>
            {/* Update the display to check isMatchLive */}
            <p className="text-sm text-gray-500">
              {isMatchLive ? <span className="text-red-500 font-bold">LIVE</span> : matchProgress}
            </p>
            {/* Only show match progress if live */}
            {isMatchLive && <p className="text-sm text-red-500">{matchProgress}</p>}
          </div>
          <div className="flex flex-col items-center">
            <img
              src={match.strAwayTeamBadge}
              alt={match.strAwayTeam}
              className={`w-12 h-12 md:w-16 md:h-16 mb-2 cursor-pointer rounded-full ${
                userPrediction && userPrediction.prediction === 'away'
                  ? `shadow-lg border-2 ${
                      theme === 'dark'
                        ? 'border-white shadow-white/10'
                        : theme === 'sunset'
                        ? 'border-[#64ffda] shadow-[#64ffda]/10'
                        : 'border-[#064e3b] shadow-[#064e3b]/10'
                    }`
                  : ''
              } grow-animation`}
              onClick={(e) => !isMatchLive && handlePredictionClick(e, 'away')}
            />
            <h4 className={`text-xl font-semibold md:text-2xl ${styles.text}`}>{match.strAwayTeam}</h4>
            <p className={`text-lg md:text-xl font-bold ${styles.text}`}>{awayScore}</p>
          </div>
        </div>
      </motion.div>
    );
  };
  

  const renderPredictions = () => {
    if (!user) {
      return <p className={styles.text}>Please log in to view and submit predictions.</p>;
    }

    const predictionData = predictions.reduce(
      (acc, pred) => {
        acc[pred.prediction] = (acc[pred.prediction] || 0) + 1;
        return acc;
      },
      { home: 0, draw: 0, away: 0 }
    );

    const data = {
      labels: ['Home', 'Draw', 'Away'],
      datasets: [
        {
          label: 'Predictions',
          data: [predictionData.home, predictionData.draw, predictionData.away],
          backgroundColor: [styles.correct, styles.highlightAccent, styles.incorrect],
          borderColor: [styles.highlight, styles.highlight, styles.highlight],
        },
      ],
    };

    return (
      <motion.div
        className={`p-4 md:p-6 rounded-lg mb-4 shadow-md ${styles.cardBg} ${styles.border}`}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h3 className={`text-xl font-semibold mb-4 ${styles.text}`}>Prediction Statistics</h3>
        <div className="w-full h-64">
          <Pie data={data} options={{ maintainAspectRatio: false }} />
        </div>
        <div className="mt-4">
          <h4 className={`text-lg font-semibold mb-2 ${styles.text}`}>User Predictions</h4>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            {predictions.map((prediction) => (
              <div
                key={prediction.id}
                className={`flex items-center p-2 md:p-4 rounded-lg shadow-md ${styles.hover} ${
                  prediction.user_id === user.id ? styles.highlightUser : ''
                } cursor-pointer`}
                onClick={() => (window.location.href = `/profile/${prediction.user_id}`)}
              >
                <img src={prediction.profile_picture} alt={prediction.name} className="w-12 h-12 rounded-full mr-4" />
                <div>
                  <a href={`/profile/${prediction.user_id}`} className={`text-lg font-semibold ${styles.text}`}>
                    {prediction.name}
                  </a>
                  <p className={`text-sm ${styles.text}`}>Prediction: {prediction.prediction}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </motion.div>
    );
  };

  const renderTeamHistory = () => {
    const renderMatchCard = (lastMatch, analyzedTeam) => {
      const isAnalyzedTeamHome = lastMatch.strHomeTeam === analyzedTeam;
      const opponentBadge = isAnalyzedTeamHome ? lastMatch.strAwayTeamBadge : lastMatch.strHomeTeamBadge;
      const opponentName = isAnalyzedTeamHome ? lastMatch.strAwayTeam : lastMatch.strHomeTeam;
      const locationText = isAnalyzedTeamHome ? `Home vs ${opponentName}` : `Away at ${opponentName}`;
  
      // Correctly format the score based on whether the analyzed team was home or away
      const analyzedTeamScore = isAnalyzedTeamHome ? lastMatch.intHomeScore : lastMatch.intAwayScore;
      const opponentTeamScore = isAnalyzedTeamHome ? lastMatch.intAwayScore : lastMatch.intHomeScore;
      const scoreDisplay = `${analyzedTeamScore} - ${opponentTeamScore}`;
  
      // Determine the match result for the analyzed team
      const isWin = analyzedTeamScore > opponentTeamScore;
      const isDraw = analyzedTeamScore === opponentTeamScore;
      const scoreColor = isDraw ? '#FFC107' : isWin ? styles.correct : styles.incorrect;
  
      return (
        <div
          key={lastMatch.idEvent}
          className={`flex flex-col md:flex-row items-center justify-between p-2 md:p-4 rounded-lg shadow-sm ${styles.hover} ${styles.secondaryCardBg} mb-2 w-full`}
        >
          <div className="flex items-center">
            <img src={opponentBadge} alt={opponentName} className="w-10 h-10 mr-4" />
            <p className={styles.text}>{locationText}</p>
          </div>
          <div className="text-center">
            <p className={`font-semibold ${scoreColor}`}>{scoreDisplay}</p>
            <p className="text-sm text-gray-500">{formatRelativeTime(lastMatch.dateEvent)}</p>
          </div>
        </div>
      );
    };
  
    const getDotColors = (matches, analyzedTeam) => {
      return matches.map((match) => {
        const isAnalyzedTeamHome = match.strHomeTeam === analyzedTeam;
        const analyzedTeamScore = isAnalyzedTeamHome ? match.intHomeScore : match.intAwayScore;
        const opponentTeamScore = isAnalyzedTeamHome ? match.intAwayScore : match.intHomeScore;
        const isWin = analyzedTeamScore > opponentTeamScore;
        const isDraw = analyzedTeamScore === opponentTeamScore;
        return isDraw ? '#FFC107' : isWin ? styles.correct : styles.incorrect;
      });
    };
  
    const homeTeamDotColors = getDotColors(additionalDetails?.homeTeamLastMatches || [], match.strHomeTeam);
    const awayTeamDotColors = getDotColors(additionalDetails?.awayTeamLastMatches || [], match.strAwayTeam);
  
    return (
      <motion.div
        className={`p-4 md:p-6 rounded-lg mb-4 shadow-md ${styles.cardBg} ${styles.border}`}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="relative mb-4">
          <h3 className={`text-xl font-semibold relative ${styles.text} flex flex-col md:flex-row items-center`}>
            <div className="flex items-center">
              <img src={match.strHomeTeamBadge} alt={match.strHomeTeam} className="inline-block w-8 h-8 mr-2" />
              <span className="relative z-10">{match.strHomeTeam} Recent Matches</span>
            </div>
            <div className="flex mt-2 md:mt-0 md:ml-4">
              {homeTeamDotColors.map((color, index) => (
                <span key={index} style={{ color: color, fontSize: '2.5rem', marginRight: '0.5rem' }}>
                  •
                </span>
              ))}
            </div>
          </h3>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div className="col-span-2">
            {additionalDetails?.homeTeamLastMatches?.map((lastMatch) => renderMatchCard(lastMatch, match.strHomeTeam))}
          </div>
          <div className="col-span-2 mt-2">
            <div className="relative mb-4">
              <h4 className={`text-lg font-semibold relative ${styles.text} flex flex-col md:flex-row items-center`}>
                <div className="flex items-center">
                  <img src={match.strAwayTeamBadge} alt={match.strAwayTeam} className="inline-block w-8 h-8 mr-2" />
                  <span className="relative z-10">{match.strAwayTeam} Recent Matches</span>
                </div>
                <div className="flex mt-2 md:mt-0 md:ml-4">
                  {awayTeamDotColors.map((color, index) => (
                    <span key={index} style={{ color: color, fontSize: '2.5rem', marginRight: '0.5rem' }}>
                      •
                    </span>
                  ))}
                </div>
              </h4>
            </div>
            {additionalDetails?.awayTeamLastMatches?.map((lastMatch) => renderMatchCard(lastMatch, match.strAwayTeam))}
          </div>
        </div>
      </motion.div>
    );
  };
  
  

  const renderUpcomingMatches = () => {
    const renderUpcomingMatchCard = (upcomingMatch, analyzedTeam) => {
      // Determine if the analyzed team is home or away in this upcoming match
      const isAnalyzedTeamHome = upcomingMatch.strHomeTeam === analyzedTeam;
  
      // Get the opponent's details based on whether the analyzed team is home or away
      const opponentBadge = isAnalyzedTeamHome ? upcomingMatch.strAwayTeamBadge : upcomingMatch.strHomeTeamBadge;
      const opponentName = isAnalyzedTeamHome ? upcomingMatch.strAwayTeam : upcomingMatch.strHomeTeam;
  
      // Determine the correct location text
      const locationText = isAnalyzedTeamHome ? `Home vs ${opponentName}` : `Away at ${opponentName}`;
  
      return (
        <div
          key={upcomingMatch.idEvent}
          className={`flex flex-col md:flex-row items-center justify-between p-2 md:p-4 rounded-lg shadow-sm ${styles.hover} ${styles.secondaryCardBg} mb-2 w-full`}
        >
          <div className="flex items-center">
            <img src={opponentBadge} alt={opponentName} className="w-10 h-10 mr-4" />
            <p className={styles.text}>{locationText}</p>
          </div>
          <div className="text-center">
            <p className={styles.text}>{getFutureTime(upcomingMatch.dateEvent)}</p>
          </div>
        </div>
      );
    };
  
    const homeTeamLogo = match.strHomeTeamBadge;
    const awayTeamLogo = match.strAwayTeamBadge;
  
    const filteredHomeUpcomingMatches = additionalDetails?.homeTeamUpcomingMatches?.filter(
      (upcomingMatch) => upcomingMatch.idEvent !== match.id
    );
  
    const filteredAwayUpcomingMatches = additionalDetails?.awayTeamUpcomingMatches?.filter(
      (upcomingMatch) => upcomingMatch.idEvent !== match.id
    );
  
    return (
      <motion.div
        className={`p-4 md:p-6 rounded-lg mb-4 shadow-md ${styles.cardBg} ${styles.border}`}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h3 className={`text-xl font-semibold mb-4 ${styles.text}`}>Upcoming Matches</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div className="col-span-2">
            <h4 className={`text-lg font-semibold mb-2 flex items-center ${styles.text}`}>
              <img src={homeTeamLogo} alt={match.strHomeTeam} className="inline-block w-8 h-8 mr-2" />
              {match.strHomeTeam} Upcoming Matches
            </h4>
            {filteredHomeUpcomingMatches.map((upcomingMatch) =>
              renderUpcomingMatchCard(upcomingMatch, match.strHomeTeam)
            )}
          </div>
          <div className="col-span-2 mt-2">
            <h4 className={`text-lg font-semibold mb-2 flex items-center ${styles.text}`}>
              <img src={awayTeamLogo} alt={match.strAwayTeam} className="inline-block w-8 h-8 mr-2" />
              {match.strAwayTeam} Upcoming Matches
            </h4>
            {filteredAwayUpcomingMatches.map((upcomingMatch) =>
              renderUpcomingMatchCard(upcomingMatch, match.strAwayTeam)
            )}
          </div>
        </div>
      </motion.div>
    );
  };
  

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center z-50 ${styles.bg}`}
      onClick={(e) => e.target === e.currentTarget && onClose()}
    >
      <div
        className={`relative p-2 md:p-4 rounded-lg shadow-lg w-full max-w-5xl overflow-auto max-h-screen ${styles.cardBg}`}
      >
        <button
          className="absolute top-2 right-4 text-gray-500 hover:text-red-500 text-2xl"
          onClick={onClose}
        >
          &times;
        </button>
        <div className="grid grid-cols-2 gap-2 mb-4">
          <button
            className={`px-2 py-2 rounded-lg font-semibold ${
              activeTab === 'overview' ? styles.highlight : styles.text
            }`}
            onClick={() => setActiveTab('overview')}
          >
            <FaListAlt className="inline-block mr-2" /> Overview
          </button>
          <button
            className={`px-2 py-2 rounded-lg font-semibold ${
              activeTab === 'predictions' ? styles.highlight : styles.text
            }`}
            onClick={() => setActiveTab('predictions')}
          >
            <FaChartBar className="inline-block mr-2" /> Predictions
          </button>
          <button
            className={`px-2 py-2 rounded-lg font-semibold ${
              activeTab === 'teamHistory' ? styles.highlight : styles.text
            }`}
            onClick={() => setActiveTab('teamHistory')}
          >
            <FaHistory className="inline-block mr-2" /> Team History
          </button>
          <button
            className={`px-2 py-2 rounded-lg font-semibold ${
              activeTab === 'upcoming' ? styles.highlight : styles.text
            }`}
            onClick={() => setActiveTab('upcoming')}
          >
            <FaCalendarAlt className="inline-block mr-2" /> Upcoming Matches
          </button>
        </div>
        {loading ? (
          <div className="flex justify-center items-center">
            <ClipLoader size={50} color={styles.highlightAccent} />
          </div>
        ) : (
          <>
            {activeTab === 'overview' && renderOverview()}
            {activeTab === 'predictions' && renderPredictions()}
            {activeTab === 'teamHistory' && renderTeamHistory()}
            {activeTab === 'upcoming' && renderUpcomingMatches()}
          </>
        )}
      </div>
    </div>
  );
};

MatchDetail.propTypes = {
  match: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  league: PropTypes.string.isRequired,
  onUpdatePrediction: PropTypes.func.isRequired,
};

export default MatchDetail;
